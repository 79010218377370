import { supabase } from './SupabaseService';
import { analytics } from './AnalyticsService';

class PublicGalleryService {
  constructor() {
    this.tableName = 'gallery_images';
  }

  async getAllImages() {
    try {
      const { data, error } = await supabase
        .from(this.tableName)
        .select('id, alt_text, caption, display_order, image_path')
        .order('display_order', { ascending: true });

      if (error) throw error;

      // Transform the data to include full image URLs
      return data.map(image => ({
        id: image.id,
        image: `${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/gallery-images/${image.image_path}`,
        alt: image.alt_text,
        caption: image.caption
      }));
    } catch (error) {
      analytics.logError(error, { component: 'PublicGalleryService', method: 'getAllImages' });
      throw error;
    }
  }
}

export const publicGalleryService = new PublicGalleryService();

// shared-components/features/admin/orders/OrdersTable.jsx

import { useState, useEffect, useCallback } from 'react';
import { orderService } from '../../../services/OrderService';
import { DashboardCard } from '../dashboard/DashboardCard';
import { THEME } from '../../../constants/designSystem';
import { formatPrice } from '../../../utils/MoneyUtils';
import { formatDeliveryDate } from '../../../utils/ShippingUtils';
import { useAuth } from '../../../hooks/useAuth';
import OrderDetails from './OrderDetails';

const OrdersTable = () => {
  const { isAuthenticated, user } = useAuth();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const pageSize = 10;
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const loadOrders = useCallback(async () => {
    // Log auth state for debugging
    console.log('Auth state:', {
      isAuthenticated,
      hasUser: !!user,
      userRole: user?.role,
      userId: user?.id
    });

    if (!isAuthenticated) {
      console.log('Skipping order load - not authenticated');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      console.log('Fetching orders for page:', currentPage);
      
      const { data, count } = await orderService.getAllOrders(currentPage, pageSize);
      
      console.log('Orders loaded:', {
        count,
        receivedOrders: data?.length ?? 0,
        hasData: !!data
      });

      setOrders(data || []);
      setTotalOrders(count || 0);
    } catch (err) {
      console.error('Failed to load orders:', {
        error: err,
        message: err.message,
        code: err.code,
        details: err?.details
      });
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [currentPage, pageSize, isAuthenticated, user]);

  useEffect(() => {
    if (isAuthenticated) {
      loadOrders();
    } else {
      setOrders([]);
      setTotalOrders(0);
    }
  }, [loadOrders, isAuthenticated]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleViewDetails = async (order) => {
    try {
      const detailedOrder = await orderService.getOrderById(order.id);
      setSelectedOrder(detailedOrder);
    } catch (err) {
      console.error('Error fetching order details:', err);
      setError(err);
    }
  };

  const renderOrderRow = (order) => (
    <tr key={order.id} className="border-b hover:bg-gray-50 cursor-pointer" onClick={() => handleViewDetails(order)}>
      <td className="py-4 px-4">{order.order_reference}</td>
      <td className="py-4 px-4">{formatDeliveryDate(new Date(order.created_at))}</td>
      <td className="py-4 px-4">{order.customer_email}</td>
      <td className="py-4 px-4">{formatPrice(order.total_amount / 100)}</td>
      <td className="py-4 px-4">
        <span className={`px-2 py-1 rounded-full text-sm
          ${order.status === 'paid' ? 'bg-green-100 text-green-800' : 
            order.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
            'bg-gray-100 text-gray-800'}`}>
          {order.status}
        </span>
      </td>
    </tr>
  );

  if (loading) {
    return (
      <DashboardCard>
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="space-y-3">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="h-12 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </DashboardCard>
    );
  }

  if (error) {
    return (
      <DashboardCard>
        <div className="p-4">
          <div className="text-red-500 font-medium mb-2">Error loading orders:</div>
          <div className="text-sm text-gray-600">
            <p className="mb-1">{error.message}</p>
            {error.details && (
              <p className="text-xs text-gray-500">{error.details}</p>
            )}
          </div>
          <button 
            onClick={loadOrders}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      </DashboardCard>
    );
  }

  return (
    <DashboardCard>
      {selectedOrder && (
        <OrderDetails 
          order={selectedOrder} 
          onClose={() => setSelectedOrder(null)} 
        />
      )}
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr className={`${THEME.COLORS.BG.CONTENT.HEADER} border-b`}>
              <th className="py-3 px-4 text-left">Reference</th>
              <th className="py-3 px-4 text-left">Date</th>
              <th className="py-3 px-4 text-left">Customer</th>
              <th className="py-3 px-4 text-left">Total</th>
              <th className="py-3 px-4 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {orders.length > 0 ? (
              orders.map(renderOrderRow)
            ) : (
              <tr>
                <td colSpan="5" className="py-8 text-center">
                  <p className={THEME.COLORS.TEXT.MUTED}>No orders found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {totalOrders > pageSize && (
        <div className="mt-4 flex justify-center gap-2">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-1 rounded ${
              currentPage === 1 
                ? 'bg-gray-100 text-gray-400' 
                : `${THEME.COLORS.BG.SECONDARY} ${THEME.COLORS.TEXT.ON_SECONDARY}`
            }`}
          >
            Previous
          </button>
          <span className={`px-3 py-1 ${THEME.COLORS.TEXT.MUTED}`}>
            Page {currentPage} of {Math.ceil(totalOrders / pageSize)}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= Math.ceil(totalOrders / pageSize)}
            className={`px-3 py-1 rounded ${
              currentPage >= Math.ceil(totalOrders / pageSize)
                ? 'bg-gray-100 text-gray-400'
                : `${THEME.COLORS.BG.SECONDARY} ${THEME.COLORS.TEXT.ON_SECONDARY}`
            }`}
          >
            Next
          </button>
        </div>
      )}
    </DashboardCard>
  );
};

export default OrdersTable;

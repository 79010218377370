import ContentLayout from '@shared/features/admin/content/ContentLayout';
import AdminProductList from '@shared/features/admin/content/shop/AdminProductList';

const AdminShopPage = () => {
  return (
    <ContentLayout title="Product Management">
      <AdminProductList />
    </ContentLayout>
  );
};

export default AdminShopPage;

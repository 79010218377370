// shared-components/features/admin/dashboard/ActivityLog.jsx

import { DashboardCard } from './DashboardCard';
import { THEME, TYPOGRAPHY } from '../../../constants/designSystem';

// This would typically come from your activity logging service
const mockActivities = [
  {
    id: 1,
    action: 'Updated tour schedule',
    user: 'admin@example.com',
    timestamp: '2024-01-01T10:30:00Z'
  },
  {
    id: 2,
    action: 'Added new gallery items',
    user: 'admin@example.com',
    timestamp: '2024-01-01T09:15:00Z'
  },
  {
    id: 3,
    action: 'Modified product pricing',
    user: 'admin@example.com',
    timestamp: '2024-01-01T08:45:00Z'
  }
];

export const ActivityLog = () => {
  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  return (
    <DashboardCard title="Recent Activity">
      <div className="space-y-4">
        {mockActivities.map((activity) => (
          <div
            key={activity.id}
            className={`
              p-4 rounded-md
              ${THEME.COLORS.BG.CONTENT.ITEM}
            `}
          >
            <p className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT} mb-1`}>
              {activity.action}
            </p>
            <div className={`
              ${TYPOGRAPHY.BODY.SMALL}
              ${THEME.COLORS.TEXT.MUTED}
              flex flex-col sm:flex-row sm:justify-between gap-1 sm:gap-0
            `}>
              <span>{activity.user}</span>
              <span>{formatTimestamp(activity.timestamp)}</span>
            </div>
          </div>
        ))}
      </div>
    </DashboardCard>
  );
};

// shared-components/features/admin/PerformanceMonitor.jsx

import { useEffect, useState } from 'react';
import { analytics } from '@shared/services/AnalyticsService';
import { THEME, TYPOGRAPHY } from '@shared/constants/designSystem';
import { DashboardCard } from './dashboard/DashboardCard';
import MetricTrends from './metrics/MetricTrends';
import ExportMetrics from './metrics/ExportMetrics';

const formatMetricValue = (key, value) => {
  if (!value) return 'N/A';
  
  switch (key) {
    case 'memoryUsage':
      return `${value.toFixed(1)} MB`;
    case 'loadTime':
    case 'interactionTime':
      return `${value.toFixed(0)} ms`;
    case 'errorRate':
      return `${value.toFixed(2)}/hr`;
    default:
      return value.toFixed(2);
  }
};

const metricLabels = {
  loadTime: 'Page Load Time',
  interactionTime: 'Interaction Time',
  memoryUsage: 'Memory Usage',
  errorRate: 'Error Rate'
};

const metricCategories = {
  performance: ['loadTime', 'memoryUsage'],
  interaction: ['interactionTime'],
  reliability: ['errorRate']
};

function PerformanceMonitor() {
  const [metrics, setMetrics] = useState({
    loadTime: null,
    interactionTime: null,
    memoryUsage: null,
    errorRate: null
  });

  useEffect(() => {
    const updateMetrics = () => {
      setMetrics({
        loadTime: analytics.getMetricAverage('page_load'),
        interactionTime: analytics.getMetricAverage('interaction_time'),
        memoryUsage: analytics.getMetricAverage('memory_usage'),
        errorRate: analytics.getErrorRate()
      });
    };

    updateMetrics();
    const interval = setInterval(updateMetrics, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="space-y-6">
      {Object.entries(metricCategories).map(([category, metricKeys]) => (
        <DashboardCard 
          key={category}
          title={category.charAt(0).toUpperCase() + category.slice(1) + ' Metrics'}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {metricKeys.map(key => (
              <div 
                key={key}
                className={`p-4 rounded-lg ${THEME.COLORS.BG.MUTED}`}
              >
                <h3 className={`${TYPOGRAPHY.BODY.SMALL} ${THEME.COLORS.TEXT.MUTED} mb-1`}>
                  {metricLabels[key]}
                </h3>
                <p className={`${TYPOGRAPHY.HEADING.H2} ${THEME.COLORS.TEXT.DEFAULT}`}>
                  {formatMetricValue(key, metrics[key])}
                </p>
              </div>
            ))}
          </div>
        </DashboardCard>
      ))}
      
      <MetricTrends 
        metrics={['loadTime', 'memoryUsage', 'interactionTime', 'errorRate']}
      />
      
      <ExportMetrics />
    </div>
  )
}

export default PerformanceMonitor

// shared-components/features/gallery/GalleryItem.jsx

import PropTypes from 'prop-types'
import ResponsiveImage from '@shared/ui/ResponsiveImage'
import { cn } from '@shared/lib/utils'

const GalleryItem = ({ 
  image,
  alt,
  caption,
  className,
  index,
  onImageClick 
}) => {
  return (
    <div 
      className={cn(
        "relative w-full cursor-pointer",
        "group",
        "hover:shadow-lg transition-all duration-300 ease-in-out",
        "transform hover:scale-[1.02]",
        "motion-safe:animate-fadeIn",
        "shadow-md",
        className
      )}
      onClick={() => onImageClick?.(index)}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault()
          onImageClick?.(index)
        }
      }}
    >
      <div className="relative w-full pt-[100%] rounded-xl overflow-hidden border border-gray-200 dark:border-gray-700">
        <div className="absolute top-[-40px] left-0 right-0 bottom-0">
          <ResponsiveImage
            src={image}
            alt={alt}
            loading="lazy"
            sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, (max-width: 1024px) 33vw, 25vw"
          />
        </div>
        <div 
          className={cn(
            "absolute inset-0 bg-black/40 flex items-center justify-center",
            "opacity-0 group-hover:opacity-100 transition-opacity duration-300",
            "backdrop-blur-[2px]"
          )}
        >
          <div className="text-white text-center">
            <span className="sr-only">Click to view full image</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-8 w-8 mx-auto" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="mt-2 px-3 pb-3 min-h-[3.5rem]"> {/* Added min-h-[3.5rem] to maintain consistent height */}
        <p className="text-sm text-gray-600 dark:text-gray-300 line-clamp-2">
          {caption || '\u00A0'} {/* Add non-breaking space if no caption */}
        </p>
      </div>
    </div>
  )
}

GalleryItem.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string,
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
  onImageClick: PropTypes.func
}

export default GalleryItem
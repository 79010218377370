// src/components/shop/PaymentForm.jsx
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'

function PaymentForm({ amount, currency = 'gbp', metadata = {} }) {
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState(false)

  // Add logging when component receives props
  console.log('PaymentForm initialized with metadata:', metadata)

  // In PaymentForm.jsx
const handleSubmit = async (event) => {
  event.preventDefault()
  
  if (!stripe || !elements) {
    return
  }

  setProcessing(true)
  setError(null)

  try {
    // Log the full metadata object before processing
    console.log('Starting payment with full metadata:', {
      metadata,
      metadataStringified: JSON.stringify(metadata),
      metadataKeys: Object.keys(metadata)
    })

    const { error: submitError } = await elements.submit()
    if (submitError) {
      throw submitError
    }

    const functionUrl = `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/create-payment-intent`
    
    // Create a clean metadata object with explicit properties
    const paymentMetadata = {
      orderReference: metadata.orderReference || '',
      customerEmail: metadata.customerEmail || '',
      shippingAddress: typeof metadata.shippingAddress === 'string' 
        ? metadata.shippingAddress 
        : JSON.stringify(metadata.shippingAddress),
      shippingOption: typeof metadata.shippingOption === 'string'
        ? metadata.shippingOption
        : JSON.stringify(metadata.shippingOption),
      vatAmount: metadata.vatAmount || 0,
      items: typeof metadata.items === 'string'
        ? metadata.items
        : JSON.stringify(metadata.items)
    }

    // Log the prepared payment metadata
    console.log('Prepared payment metadata:', paymentMetadata)

    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${import.meta.env.VITE_SUPABASE_ANON_KEY}`
      },
      body: JSON.stringify({
        amount: Math.round(amount * 100),
        currency,
        metadata: paymentMetadata // Use the cleaned metadata
      })
    })

    // Rest of the code...

      // Add logging for response
      const responseText = await response.text()
      console.log('Payment intent response:', {
        ok: response.ok,
        status: response.status,
        body: responseText
      })

      if (!response.ok) {
        console.error('Payment intent error:', {
          status: response.status,
          statusText: response.statusText,
          body: responseText
        })
        throw new Error(`Payment intent failed: ${response.statusText}`)
      }

      const data = JSON.parse(responseText)
      
      if (data.error) {
        throw new Error(data.error)
      }

      const { clientSecret } = data

      // Log confirmation attempt
      console.log('Confirming payment with metadata:', metadata)

      // 3. Confirm the payment
      const { error: confirmError } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/payment/confirm`,
        },
      })

      if (confirmError) {
        throw confirmError
      }
    } catch (err) {
      console.error('Payment error:', err)
      setError(err.message)
    } finally {
      setProcessing(false)
    }
  }

  if (!stripe || !elements) {
    return (
      <div className="p-4 text-center">
        <div className="animate-pulse">
          Loading payment form...
        </div>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="p-4 border rounded-lg">
        <PaymentElement />
      </div>

      {error && (
        <div className="p-4 text-red-700 bg-red-100 rounded-lg">
          {error}
        </div>
      )}

      <button
        type="submit"
        disabled={processing || !stripe}
        className={`
          w-full px-4 py-2 rounded-lg
          bg-blue-600 text-white
          disabled:opacity-50 disabled:cursor-not-allowed
          hover:bg-blue-700 transition-colors
        `}
      >
        {processing ? 'Processing...' : `Pay ${currency.toUpperCase()} ${amount.toFixed(2)}`}
      </button>
    </form>
  )
}

PaymentForm.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.oneOf(['gbp', 'eur']),
  metadata: PropTypes.shape({
    orderReference: PropTypes.string,
    customerEmail: PropTypes.string,
    shippingAddress: PropTypes.string,
    shippingOption: PropTypes.string,
    vatAmount: PropTypes.number,
    items: PropTypes.string
  })
}

PaymentForm.defaultProps = {
  currency: 'gbp',
  metadata: {}
}

export default PaymentForm
import { supabase } from './SupabaseService';

class AdminProductService {
  constructor() {
    this.tableName = 'products';
  }

  async getAllProducts() {
    const { data, error } = await supabase
      .from(this.tableName)
      .select(`
        *,
        product_categories!inner (
          id,
          name
        )
      `)
      .order('name', { ascending: true });

    if (error) throw error;
    return data;
  }

  async getProductById(id) {
    const { data, error } = await supabase
      .from(this.tableName)
      .select(`
        *,
        product_categories (
          id,
          name
        )
      `)
      .eq('id', id)
      .single();

    if (error) throw error;
    return data;
  }

  async createProduct(productData) {
    const { data, error } = await supabase
      .from(this.tableName)
      .insert([{
        name: productData.name,
        description: productData.description,
        price: productData.price,
        image: productData.image,
        in_stock: productData.inStock,
        stock_quantity: productData.stockQuantity,
        max_quantity: productData.maxQuantity,
        category_id: productData.categoryId,
        status: productData.status
      }])
      .select(`
        *,
        product_categories (
          id,
          name
        )
      `)
      .single();

    if (error) throw error;
    return data;
  }

  async updateProduct(id, productData) {
    const { data, error } = await supabase
      .from(this.tableName)
      .update({
        name: productData.name,
        description: productData.description,
        price: productData.price,
        image: productData.image,
        in_stock: productData.inStock,
        stock_quantity: productData.stockQuantity,
        max_quantity: productData.maxQuantity,
        category_id: productData.categoryId,
        status: productData.status
      })
      .eq('id', id)
      .select(`
        *,
        product_categories (
          id,
          name
        )
      `)
      .single();

    if (error) throw error;
    return data;
  }

  async getCategories() {
    const { data, error } = await supabase
      .from('product_categories')
      .select('*')
      .order('name');

    if (error) throw error;
    return data;
  }

  async deleteProduct(id) {
    const { error } = await supabase
      .from(this.tableName)
      .delete()
      .eq('id', id);

    if (error) throw error;
    return true;
  }

  async updateProductStatus(id, status) {
    const { data, error } = await supabase
      .from(this.tableName)
      .update({ status })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  async updateInventory(id, stockQuantity) {
    const { data, error } = await supabase
      .from(this.tableName)
      .update({ 
        stock_quantity: stockQuantity,
        in_stock: stockQuantity > 0
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  }
}

export const adminProductService = new AdminProductService();

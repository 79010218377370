// shared-components/features/admin/charts/TimeSeriesChart.jsx

import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { THEME } from '@shared/constants/designSystem';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const formatTimestamp = (timestamp) => {
  return new Date(timestamp).toLocaleTimeString();
};

const TimeSeriesChart = ({ data, label, color = 'rgb(59, 130, 246)' }) => {
  const chartData = {
    labels: data.map(d => formatTimestamp(d.timestamp)),
    datasets: [
      {
        label,
        data: data.map(d => d.value),
        borderColor: color,
        backgroundColor: color,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: THEME.COLORS.TEXT.DEFAULT.split(' ')[0].replace('text-', '')
        }
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: THEME.COLORS.TEXT.MUTED.split(' ')[0].replace('text-', '')
        }
      },
      y: {
        grid: {
          color: THEME.COLORS.TEXT.MUTED.split(' ')[0].replace('text-', '') + '20'
        },
        ticks: {
          color: THEME.COLORS.TEXT.MUTED.split(' ')[0].replace('text-', '')
        }
      }
    }
  };

  return (
    <div className="h-64">
      <Line data={chartData} options={options} />
    </div>
  );
};

TimeSeriesChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string
};

TimeSeriesChart.defaultProps = {
  color: 'rgb(59, 130, 246)'
};

export default TimeSeriesChart;

import { useState, useCallback } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import ErrorBoundary from '@shared/ui/ErrorBoundary';
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { adminGalleryService } from '@shared/services/AdminGalleryService';
import { analytics } from '@shared/services/AnalyticsService';
import DraggableGalleryItem from '@shared/features/gallery/DraggableGalleryItem';
import ImageUploadForm from '@shared/features/gallery/ImageUploadForm';
import ImageMetadataForm from '@shared/features/gallery/ImageMetadataForm';
import Modal from '@shared/ui/Modal';
import { THEME } from '@shared/constants/designSystem';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import ContentLayout from '@shared/features/admin/content/ContentLayout';

const AdminGalleryPage = () => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [editingImage, setEditingImage] = useState(null);
  const queryClient = useQueryClient();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  const { data: images = [], isLoading, error } = useQuery({
    queryKey: ['adminGallery'],
    queryFn: () => adminGalleryService.getAllImages()
  });

  const uploadMutation = useMutation({
    mutationFn: ({ file, metadata }) => adminGalleryService.uploadImage(file, {
      ...metadata,
      displayOrder: images.length
    }),
    onSuccess: () => {
      queryClient.invalidateQueries(['adminGallery']);
      setIsUploadModalOpen(false);
      analytics.trackEvent('admin', 'gallery_image_uploaded');
    }
  });

  const updateMutation = useMutation({
    mutationFn: ({ id, updates }) => adminGalleryService.updateImage(id, updates),
    onSuccess: () => {
      queryClient.invalidateQueries(['adminGallery']);
      setEditingImage(null);
      analytics.trackEvent('admin', 'gallery_image_updated');
    }
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => adminGalleryService.deleteImage(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['adminGallery']);
      analytics.trackEvent('admin', 'gallery_image_deleted');
    }
  });

  const reorderMutation = useMutation({
    mutationFn: (imageIds) => adminGalleryService.updateOrder(imageIds),
    onSuccess: () => {
      queryClient.invalidateQueries(['adminGallery']);
      analytics.trackEvent('admin', 'gallery_images_reordered');
    }
  });

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = images.findIndex(img => img.id === active.id);
      const newIndex = images.findIndex(img => img.id === over.id);
      
      const newOrder = arrayMove(images, oldIndex, newIndex);
      reorderMutation.mutate(newOrder.map(img => img.id));
    }
  }, [images, reorderMutation]);

  if (isLoading) {
    return <ContentLayout title="Gallery Management"><div className="p-4">Loading gallery...</div></ContentLayout>;
  }

  if (error) {
    return <ContentLayout title="Gallery Management"><div className="p-4 text-red-600">Error loading gallery: {error.message}</div></ContentLayout>;
  }

  return (
    <ErrorBoundary>
      <ContentLayout title="Gallery Management">
        <div className="p-4">
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => setIsUploadModalOpen(true)}
            className={`px-4 py-2 rounded-lg ${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY}`}
          >
            Upload Image
          </button>
        </div>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={images.map(img => img.id)} strategy={rectSortingStrategy}>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {images.map((image, index) => (
                <DraggableGalleryItem
                  key={image.id}
                  id={image.id}
                  image={`${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/gallery-images/${image.image_path}`}
                  alt={image.alt_text}
                  index={index}
                  onEdit={() => setEditingImage(image)}
                  onDelete={() => {
                    if (window.confirm('Are you sure you want to delete this image?')) {
                      deleteMutation.mutate(image.id);
                    }
                  }}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>

        <Modal
          isOpen={isUploadModalOpen}
          onClose={() => setIsUploadModalOpen(false)}
          title="Upload New Image"
        >
          <ImageUploadForm
            onUpload={(file, metadata) => uploadMutation.mutate({ file, metadata })}
            onCancel={() => setIsUploadModalOpen(false)}
          />
        </Modal>

        <Modal
          isOpen={!!editingImage}
          onClose={() => setEditingImage(null)}
          title="Edit Image Details"
        >
          {editingImage && (
            <ImageMetadataForm
              image={editingImage}
              onSave={(updates) => updateMutation.mutate({
                id: editingImage.id,
                updates: {
                  alt_text: updates.altText,
                  caption: updates.caption
                }
              })}
              onCancel={() => setEditingImage(null)}
            />
          )}
        </Modal>
        </div>
      </ContentLayout>
    </ErrorBoundary>
  );
};

export default AdminGalleryPage;

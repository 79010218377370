// shared-components/features/admin/AdminUserInfo.jsx

import { useAuth } from '../../hooks/useAuth';
import { TYPOGRAPHY, THEME } from '../../constants/designSystem';

export const AdminUserInfo = () => {
  const { user } = useAuth();

  return (
    <div className="flex items-center">
      <div className="ml-3">
        <p className={`${TYPOGRAPHY.BODY.SMALL} ${THEME.COLORS.TEXT.DEFAULT}`}>
          {user?.email}
        </p>
        <p className={`${TYPOGRAPHY.BODY.SMALL} ${THEME.COLORS.TEXT.MUTED}`}>
          Administrator
        </p>
      </div>
    </div>
  );
};

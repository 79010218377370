// shared-components/content/Section.jsx

import PropTypes from 'prop-types'
import { SECTION_WIDTHS } from '../constants/contentTypes'
import { SPACING } from '../constants/designSystem'

const widthClasses = {
  [SECTION_WIDTHS.NARROW]: 'max-w-xl sm:max-w-2xl',
  [SECTION_WIDTHS.NORMAL]: 'max-w-2xl sm:max-w-3xl lg:max-w-4xl',
  [SECTION_WIDTHS.WIDE]: 'max-w-3xl sm:max-w-4xl lg:max-w-6xl',
  [SECTION_WIDTHS.FULL]: 'max-w-full'
}

const Section = ({ children, width = SECTION_WIDTHS.NORMAL, className = '' }) => {
  return (
    <section className={`mx-auto ${SPACING.DEFAULT} ${widthClasses[width]} ${className}`}>
      {children}
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOf(Object.values(SECTION_WIDTHS)),
  className: PropTypes.string
}

export default Section

// shared-components/features/gallery/ViewModeToggle.jsx

import PropTypes from 'prop-types'
import { cn } from '../../lib/utils'
import { THEME } from '../../constants/designSystem'
import { analytics } from '../../services/AnalyticsService'

const ViewModeToggle = ({ mode, onModeChange }) => {
  const handleModeChange = (newMode) => {
    analytics.trackEvent('gallery', 'view_mode_change', newMode);
    onModeChange(newMode);
  };
  return (
    <div className="flex items-center gap-2 mb-4">
      <button
        onClick={() => handleModeChange('grid')}
        className={cn(
          "px-3 py-2 rounded-lg transition-all duration-300",
          mode === 'grid' 
            ? `${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY}`
            : `${THEME.COLORS.BG.MUTED} ${THEME.COLORS.TEXT.MUTED} hover:bg-gray-200 dark:hover:bg-gray-700`
        )}
        aria-pressed={mode === 'grid'}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
        </svg>
      </button>
      <button
        onClick={() => handleModeChange('carousel')}
        className={cn(
          "px-3 py-2 rounded-lg transition-all duration-300",
          mode === 'carousel'
            ? `${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY}`
            : `${THEME.COLORS.BG.MUTED} ${THEME.COLORS.TEXT.MUTED} hover:bg-gray-200 dark:hover:bg-gray-700`
        )}
        aria-pressed={mode === 'carousel'}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
      </button>
    </div>
  )
}

ViewModeToggle.propTypes = {
  mode: PropTypes.oneOf(['grid', 'carousel']).isRequired,
  onModeChange: PropTypes.func.isRequired
}

export default ViewModeToggle

import { useState } from 'react'
import ReactDOM from 'react-dom/client'
import { useCart } from '@shared/hooks/useCart'
import { formatPrice } from '@shared/utils/MoneyUtils'
import { 
  generateOrderReference, 
  sendOrderConfirmationEmail, 
  handlePaymentError, 
  getSupportInfo
} from '@shared/utils/CheckoutUtils'
import PaymentErrorBoundary from '@shared/features/shop/payment/PaymentErrorBoundary'
import { THEME, TYPOGRAPHY, Z_INDEX } from '@shared/constants/designSystem'
import OrderConfirmation from '@shared/features/shop/payment/OrderConfirmation'
import Button from '@shared/ui/Button'
import AddressForm from '@shared/features/shop/payment/AddressForm'
import ShippingOptions from '@shared/features/shop/payment/ShippingOptions'
import PaymentForm from '@shared/features/shop/payment/PaymentForm'
import StripeProvider from '@shared/features/shop/payment/stripe/StripeProvider'

function CartSummary() {
  const { 
    items, 
    clearCart, 
    shippingAddress, 
    setShippingAddress,
    shippingOption,
    setShippingOption,
    setPaymentStatus,
    setPaymentError
  } = useCart()
  const [showAddressForm, setShowAddressForm] = useState(false)

  const subtotal = items.reduce((sum, item) => sum + (item.price * item.quantity), 0)
  const shippingCost = shippingOption?.price || 0
  const total = subtotal + shippingCost
  const itemCount = items.reduce((count, item) => count + item.quantity, 0)

  if (items.length === 0) {
    return (
      <div 
        className={`p-4 text-center ${THEME.COLORS.BG.DEFAULT}`}
        role="status"
        aria-label="Empty cart"
      >
        <p className={`${THEME.COLORS.TEXT.MUTED} mb-4`}>
          Your cart is empty
        </p>
        <Button
          variant="outline"
          size="sm"
          onClick={() => window.history.back()}
        >
          Continue Shopping
        </Button>
      </div>
    )
  }

  return (
    <div className={`space-y-4 max-w-full overflow-x-hidden ${THEME.COLORS.BG.DEFAULT} p-6 rounded-lg shadow-sm`} role="region" aria-label="Cart summary">
      <div className="divide-y">
        {items.map(item => (
          <div 
            key={item.id}
            className="py-4 flex items-center justify-between"
          >
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
              {item.image && (
                <img 
                  src={item.image} 
                  alt=""
                  className="w-16 h-16 object-cover rounded"
                />
              )}
              <div>
                <h3 className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT}`}>
                  {item.name}
                </h3>
                <p className={THEME.COLORS.TEXT.MUTED}>
                  Quantity: {item.quantity}
                </p>
              </div>
            </div>
            <div className="text-right">
              <p className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT}`}>
                {formatPrice(item.price * item.quantity)}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="pt-4 border-t">
        <div className="space-y-2 mb-4">
          <div className="flex justify-between">
            <span className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT}`}>
              Subtotal ({itemCount} items)
            </span>
            <div className={THEME.COLORS.TEXT.DEFAULT}>
              {formatPrice(subtotal)}
            </div>
          </div>
          
          <div className="flex justify-between pt-2 border-t">
            <span className={`${TYPOGRAPHY.BODY.LARGE} ${THEME.COLORS.TEXT.DEFAULT}`}>
              Total (inc. VAT)
            </span>
            <div className={`${TYPOGRAPHY.BODY.LARGE} ${THEME.COLORS.TEXT.DEFAULT}`}>
              {formatPrice(total)}
            </div>
          </div>
        </div>
        
        {!showAddressForm ? (
          <div className="flex gap-4">
            <Button
              variant="outline"
              onClick={clearCart}
              className="w-full"
              aria-label="Clear cart"
            >
              Clear Cart
            </Button>
            <Button
              variant="primary"
              className="w-full"
              onClick={() => setShowAddressForm(true)}
              aria-label="Proceed to checkout"
            >
              Checkout
            </Button>
          </div>
        ) : (
          <div className="border-t pt-6 space-y-6 max-w-2xl mx-auto">
            <div>
              <h3 className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT}`}>
                Shipping Address
              </h3>
              <AddressForm 
                onSubmit={(address) => {
                  setShippingAddress(address)
                  setShippingOption(null)
                }}
                initialAddress={shippingAddress}
              />
            </div>
            
            {shippingAddress && (
              <div className="border-t pt-6">
                <ShippingOptions
                  countryCode={shippingAddress.country}
                  selectedOption={shippingOption}
                  onSelect={setShippingOption}
                />
              </div>
            )}

            {shippingOption && (
              <>
                <div className="flex justify-between pt-4 border-t">
                  <span className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT}`}>
                    Shipping
                  </span>
                  <div className={THEME.COLORS.TEXT.DEFAULT}>
                    {formatPrice(shippingOption.price)}
                  </div>
                </div>

                <div className="border-t pt-6">
                  <h3 className={`${TYPOGRAPHY.HEADING.H3} mb-4`}>Payment</h3>
                  <PaymentErrorBoundary onReset={() => setPaymentError(null)}>
                    <StripeProvider 
                      amount={total} 
                      currency="gbp"
                    >
                      <PaymentForm
                        amount={total}
                        currency="gbp"
                        countryCode={shippingAddress.country}
                        metadata={{
                          orderReference: generateOrderReference(),
                          customerEmail: shippingAddress.email || '',
                          shippingAddress: JSON.stringify({
                            name: shippingAddress.name,
                            street: shippingAddress.street,
                            city: shippingAddress.city,
                            country: shippingAddress.country,
                            postalCode: shippingAddress.postalCode
                          }),
                          shippingOption: JSON.stringify({
                            id: shippingOption.id,
                            name: shippingOption.name,
                            price: shippingOption.price
                          }),
                          vatAmount: Math.round(total * 0.2),
                          items: JSON.stringify(items.map(item => ({
                            productId: item.productId,
                            quantity: item.quantity,
                            price: Math.round(item.price * 100)
                          })))
                        }}
                        onPaymentComplete={async (paymentIntent) => {
                          try {
                            const orderReference = generateOrderReference()
                            const orderDate = new Date()
                            
                            await sendOrderConfirmationEmail({
                              orderReference,
                              orderDate,
                              items,
                              shippingAddress,
                              shippingOption,
                              totalAmount: total,
                              paymentIntent
                            })

                            setPaymentStatus('success')
                            clearCart()

                            // Show order confirmation
                            const confirmationDialog = document.createElement('div')
                            confirmationDialog.className = `fixed inset-0 ${THEME.COLORS.BG.DEFAULT} bg-opacity-50 flex items-center justify-center p-4 ${Z_INDEX.MODAL}`
                            confirmationDialog.innerHTML = `
                              <div class="w-full max-w-2xl">
                                <div id="confirmation-content"></div>
                              </div>
                            `
                            document.body.appendChild(confirmationDialog)

                            const root = ReactDOM.createRoot(confirmationDialog.querySelector('#confirmation-content'))
                            root.render(
                              <OrderConfirmation
                                orderReference={orderReference}
                                orderDate={orderDate}
                                items={items}
                                shippingAddress={shippingAddress}
                                shippingOption={shippingOption}
                                totalAmount={total}
                                onClose={() => {
                                  root.unmount()
                                  confirmationDialog.remove()
                                  window.location.href = '/shop'
                                }}
                              />
                            )
                          } catch (error) {
                            console.error('Order completion error:', error)
                            const handledError = handlePaymentError(error)
                            setPaymentError(handledError)
                            
                            // Show support contact for non-recoverable errors
                            if (!handledError.recoverable) {
                              const supportInfo = getSupportInfo()
                              const supportDialog = document.createElement('div')
                              supportDialog.className = `fixed inset-0 ${THEME.COLORS.BG.DEFAULT} bg-opacity-50 flex items-center justify-center p-4 ${Z_INDEX.MODAL}`
                              supportDialog.innerHTML = `
                                <div class="${THEME.COLORS.BG.DEFAULT} p-6 rounded-lg max-w-md w-full">
                                  <h3 class="${TYPOGRAPHY.HEADING.H3} mb-4">Need Help?</h3>
                                  <p class="${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT} mb-4">Our support team is here to assist you:</p>
                                  <ul class="space-y-2 mb-6">
                                    <li class="${THEME.COLORS.TEXT.DEFAULT}">Email: ${supportInfo.email}</li>
                                    <li class="${THEME.COLORS.TEXT.DEFAULT}">Phone: ${supportInfo.phone}</li>
                                    <li class="${THEME.COLORS.TEXT.DEFAULT}">Hours: ${supportInfo.hours}</li>
                                  </ul>
                                  <button 
                                    class="w-full px-4 py-2 rounded-lg ${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY} ${THEME.COLORS.HOVER.BG.PRIMARY}"
                                    onclick="this.closest('.fixed').remove()"
                                  >
                                    Close
                                  </button>
                                </div>
                              `
                              document.body.appendChild(supportDialog)
                            }
                          }
                        }}
                        onError={(error) => {
                          setPaymentError(error)
                        }}
                      />
                    </StripeProvider>  
                  </PaymentErrorBoundary>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default CartSummary
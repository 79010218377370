// shared-components/features/admin/metrics/MetricTrends.jsx

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { analytics } from '@shared/services/AnalyticsService';
import { DashboardCard } from '@shared/features/admin/dashboard/DashboardCard';
import TimeSeriesChart from '@shared/features/admin/charts/TimeSeriesChart';
import { THEME, TYPOGRAPHY } from '@shared/constants/designSystem';

const timeWindows = {
  '1h': 3600000,
  '6h': 21600000,
  '24h': 86400000
};

const colors = {
  loadTime: 'rgb(59, 130, 246)', // blue
  memoryUsage: 'rgb(234, 88, 12)', // orange
  interactionTime: 'rgb(22, 163, 74)', // green
  errorRate: 'rgb(220, 38, 38)' // red
};

const MetricTrends = ({ metrics }) => {
  const [timeWindow, setTimeWindow] = useState('1h');
  const [historicalData, setHistoricalData] = useState({});

  useEffect(() => {
    const updateHistoricalData = () => {
      const newData = {};
      metrics.forEach(metric => {
        newData[metric] = analytics.getMetricHistory(metric, timeWindows[timeWindow]);
      });
      setHistoricalData(newData);
    };

    updateHistoricalData();
    const interval = setInterval(updateHistoricalData, 5000);
    return () => clearInterval(interval);
  }, [metrics, timeWindow]);

  return (
    <DashboardCard title="Metric Trends">
      <div className="mb-4 flex justify-end space-x-2">
        {Object.keys(timeWindows).map(window => (
          <button
            key={window}
            onClick={() => setTimeWindow(window)}
            className={`
              px-3 py-1 rounded-md text-sm
              ${timeWindow === window 
                ? `${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY}`
                : `${THEME.COLORS.BG.MUTED} ${THEME.COLORS.TEXT.MUTED}`
              }
            `}
          >
            {window}
          </button>
        ))}
      </div>
      <div className="space-y-6">
        {metrics.map(metric => (
          <div key={metric}>
            <h4 className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT} mb-2`}>
              {metric}
            </h4>
            {historicalData[metric]?.length > 0 ? (
              <TimeSeriesChart
                data={historicalData[metric]}
                label={metric}
                color={colors[metric]}
              />
            ) : (
              <p className={`${THEME.COLORS.TEXT.MUTED} text-center py-8`}>
                No historical data available
              </p>
            )}
          </div>
        ))}
      </div>
    </DashboardCard>
  );
};

MetricTrends.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MetricTrends;

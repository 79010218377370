// shared-components/constants/designSystem.js

export const ANIMATION = {
  DURATION: {
    DEFAULT: 'duration-500',
    FAST: 'duration-300',
    SLOW: 'duration-700'
  },
  EASING: {
    DEFAULT: 'ease-in-out',
    BOUNCE: 'ease-bounce'
  }
};

export const OPACITY = {
  NONE: 'opacity-0',
  LIGHT: 'opacity-40',
  MEDIUM: 'opacity-60',
  FULL: 'opacity-100'
};

export const SPACING = {
  DEFAULT: 'p-4',
  LARGE: 'p-6',
  GRID_GAP: 'gap-0'
};

export const TYPOGRAPHY = {
  TITLE: {
    LARGE: 'text-3xl lg:text-4xl font-light tracking-wider',
    MEDIUM: 'text-xl lg:text-2xl font-light tracking-wider',
    SMALL: 'text-lg font-light tracking-wider'
  },
  HEADING: {
    H1: 'text-3xl sm:text-4xl lg:text-5xl font-bold tracking-tight',
    H2: 'text-2xl sm:text-3xl lg:text-4xl font-semibold tracking-tight',
    H3: 'text-xl sm:text-2xl lg:text-3xl font-medium tracking-tight'
  },
  BODY: {
    DEFAULT: 'text-base leading-relaxed',
    LARGE: 'text-lg leading-relaxed',
    SMALL: 'text-sm leading-relaxed'
  },
  PROSE: {
    DEFAULT: 'prose prose-base sm:prose-lg lg:prose-xl',
    COMPACT: 'prose prose-sm sm:prose-base lg:prose-lg'
  }
};

export const BREAKPOINTS = {
  SM: '640px',
  MD: '768px',
  LG: '1024px',
  XL: '1280px'
};

export const ASPECT_RATIOS = {
  SQUARE: '100%',
  SHORT: '50%',
  TALL: '125%'
};

export const Z_INDEX = {
  BASE: 'z-0',
  OVERLAY: 'z-10',
  MODAL: 'z-50'
};

export const GRID = {
  COLS: {
    DEFAULT: 'grid-cols-1',
    MD: 'md:grid-cols-2',
    LG: 'lg:grid-cols-3'
  },
  SPANS: {
    TALL: 'row-span-3',    // Tallest
    SQUARE: 'row-span-2',  // Normal square
    SHORT: 'row-span-1'    // Short
  }
};

export const THEME = {
  COLORS: {
    BG: {
      DEFAULT: 'bg-white dark:bg-gray-900',
      MUTED: 'bg-gray-50 dark:bg-gray-800',
      PRIMARY: 'bg-primary-600 dark:bg-primary-500',
      SECONDARY: 'bg-secondary-600 dark:bg-secondary-500',
      ACCENT: 'bg-accent-600 dark:bg-accent-500',
      CONTENT: {
        ITEM: 'bg-white dark:bg-gray-800',
        HOVER: 'hover:bg-gray-50 dark:hover:bg-gray-700'
      }
    },
    TEXT: {
      DEFAULT: 'text-gray-900 dark:text-white',
      MUTED: 'text-gray-600 dark:text-gray-50',
      SECONDARY: 'text-gray-700 dark:text-white',
      ON_PRIMARY: 'text-white',
      ON_SECONDARY: 'text-white',
      ON_ACCENT: 'text-white'
    },
    BORDER: {
      DEFAULT: 'border-border dark:border-border-dark',
      PRIMARY: 'border-primary-600 dark:border-primary-500',
      SECONDARY: 'border-secondary-600 dark:border-secondary-500',
      ACCENT: 'border-accent-600 dark:border-accent-500'
    },
    HOVER: {
      BG: {
        DEFAULT: 'hover:bg-surface-muted dark:hover:bg-surface-muted-dark',
        PRIMARY: 'hover:bg-primary-700 dark:hover:bg-primary-600',
        SECONDARY: 'hover:bg-secondary-700 dark:hover:bg-secondary-600',
        ACCENT: 'hover:bg-accent-700 dark:hover:bg-accent-600'
      },
      TEXT: {
        DEFAULT: 'hover:text-white',
        MUTED: 'hover:text-white',
        GRID: 'text-gray-100 dark:text-white'
      }
    }
  }
};

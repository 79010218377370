import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import { THEME, TYPOGRAPHY } from '../../../constants/designSystem'
import { getShippingOptions, calculateDeliveryDates, formatDeliveryDate } from '../../../utils/CheckoutUtils'
import { formatPrice } from '../../../utils/MoneyUtils'

// Separate ShippingOption component for better performance
const ShippingOption = memo(function ShippingOption({ 
  option, 
  isSelected, 
  onSelect 
}) {
  const deliveryInfo = useMemo(() => {
    const { minDate, maxDate } = calculateDeliveryDates(option)
    const deliveryRange = option.estimatedDays.min === option.estimatedDays.max
      ? formatDeliveryDate(minDate)
      : `${formatDeliveryDate(minDate)} - ${formatDeliveryDate(maxDate)}`
    return deliveryRange
  }, [option])

  return (
    <label
      className={`
        block p-4 rounded-lg border cursor-pointer
        min-h-[4.5rem]
        touch-manipulation
        ${isSelected 
          ? 'border-primary-500 bg-primary-50 dark:bg-primary-900/10'
          : 'border-gray-200 dark:border-gray-700'}
        hover:border-primary-500 transition-colors
      `}
    >
      <div className="flex items-center gap-4">
        <input
          type="radio"
          name="shipping"
          value={option.id}
          checked={isSelected}
          onChange={() => onSelect(option)}
          className="text-primary-600"
        />
        <div className="flex-grow">
          <div className="flex justify-between items-start">
            <div>
              <span className="font-medium">{option.name}</span>
              <p className={`text-sm ${THEME.COLORS.TEXT.MUTED}`}>
                {option.description}
              </p>
            </div>
            <span className="font-semibold">
              {formatPrice(option.price)}
            </span>
          </div>
          <p className={`text-sm ${THEME.COLORS.TEXT.MUTED} mt-1`}>
            Estimated delivery: {deliveryInfo}
          </p>
        </div>
      </div>
    </label>
  )
})

ShippingOption.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    estimatedDays: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired
    }).isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired
}

function ShippingOptions({ countryCode, selectedOption, onSelect }) {
  const options = useMemo(
    () => getShippingOptions(countryCode),
    [countryCode]
  )
  
  return (
    <div className="space-y-4">
      <h3 className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT}`}>
        Shipping Method
      </h3>
      <div className="space-y-2">
        {options.map(option => (
          <ShippingOption
            key={option.id}
            option={option}
            isSelected={selectedOption?.id === option.id}
            onSelect={onSelect}
          />
        ))}
      </div>
    </div>
  )
}

ShippingOptions.propTypes = {
  countryCode: PropTypes.string.isRequired,
  selectedOption: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    estimatedDays: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired
    }).isRequired,
    description: PropTypes.string.isRequired
  }),
  onSelect: PropTypes.func.isRequired
}

export default memo(ShippingOptions)
// shared-components/features/admin/content/ContentCrudBase.jsx

import PropTypes from 'prop-types';
import { THEME, TYPOGRAPHY, SPACING } from '../../../constants/designSystem';
import StatusIndicator from './StatusIndicator';

const ContentCrudBase = ({
  title,
  items,
  onAdd,
  onEdit,
  onDelete,
  renderItem,
  loading = false
}) => {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className={TYPOGRAPHY.HEADING.H2}>{title}</h2>
        <button
          onClick={onAdd}
          className={`${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY} ${SPACING.DEFAULT} rounded-md`}
        >
          Add New
        </button>
      </div>

      {loading ? (
        <div className={`${THEME.COLORS.TEXT.MUTED} ${TYPOGRAPHY.BODY.DEFAULT}`}>
          Loading...
        </div>
      ) : (
        <div className="space-y-4">
          {items?.map((item) => (
            <div
              key={item.id}
              className={`${THEME.COLORS.BG.MUTED} p-4 rounded-lg flex justify-between items-center`}
            >
              {renderItem(item)}
              <div className="flex items-center gap-4">
                <StatusIndicator status={item.status} />
                <button
                  onClick={() => onEdit(item)}
                  className={`${THEME.COLORS.BG.SECONDARY} ${THEME.COLORS.TEXT.ON_SECONDARY} px-3 py-2 rounded`}
                >
                  Edit
                </button>
                <button
                  onClick={() => onDelete(item)}
                  className="text-red-600 hover:text-red-700 px-3 py-2"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ContentCrudBase.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    status: PropTypes.string.isRequired
  })),
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

ContentCrudBase.defaultProps = {
  items: [],
  loading: false
};

export default ContentCrudBase;

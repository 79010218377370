import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { authService } from '@shared/services/AuthService';
import { AdminHeader } from '@shared/features/admin/AdminHeader';
import { AdminContainer } from '@shared/features/admin/AdminContainer';
import { AdminNav } from '@shared/features/admin/AdminNav';
import { AdminMobileNav } from '@shared/features/admin/AdminMobileNav';
import { THEME } from '@shared/constants/designSystem';

export const AdminLayout = ({ children }) => {
  const navigate = useNavigate();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await authService.logout();
      navigate('/admin/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className={`flex h-screen overflow-hidden ${THEME.COLORS.BG.DEFAULT}`}>
      {/* Desktop Navigation Sidebar */}
      <div className={`
        hidden md:flex w-64 flex-shrink-0 overflow-y-auto z-40
        ${THEME.COLORS.BG.DEFAULT}
        ${THEME.COLORS.BORDER.DEFAULT}
      `}>
        <div className="w-full">
          <div className={`
            h-16 flex items-center px-6 
            ${THEME.COLORS.BORDER.DEFAULT} border-b
          `}>
            <h1 className={`text-xl font-bold ${THEME.COLORS.TEXT.DEFAULT}`}>
              Admin Panel
            </h1>
          </div>
          <div className="p-4 overflow-y-auto">
            <AdminNav />
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      <AdminMobileNav
        isOpen={isMobileNavOpen}
        onClose={() => setIsMobileNavOpen(false)}
      />

      {/* Main Content */}
      <div className={`flex-1 flex flex-col min-w-0 overflow-hidden ${THEME.COLORS.BG.DEFAULT}`}>
        <AdminHeader 
          onLogout={handleLogout}
          onMenuClick={() => setIsMobileNavOpen(true)}
        />
        <main className="flex-1 overflow-y-auto">
          <AdminContainer>
            {children}
          </AdminContainer>
        </main>
      </div>
    </div>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired
};
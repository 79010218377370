// shared-components/features/admin/content/shop/AdminProductList.jsx

import { useState, useEffect, useCallback } from 'react';
import { adminProductService } from '../../../../services/AdminProductService';
import ContentCrudBase from '../ContentCrudBase';
import AdminProductForm from './AdminProductForm';
import { THEME } from '../../../../constants/designSystem';

const AdminProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = async () => {
    try {
      const data = await adminProductService.getAllProducts();
      setProducts(data);
    } catch (error) {
      console.error('Error loading products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setSelectedProduct(null);
    setIsFormOpen(true);
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setIsFormOpen(true);
  };

  const handleDelete = async (product) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await adminProductService.deleteProduct(product.id);
        await loadProducts();
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  const handleSubmit = async (formData) => {
    try {
      if (selectedProduct) {
        await adminProductService.updateProduct(selectedProduct.id, formData);
      } else {
        await adminProductService.createProduct(formData);
      }
      await loadProducts();
      setIsFormOpen(false);
      setSelectedProduct(null);
    } catch (error) {
      console.error('Error saving product:', error);
      throw new Error(error.message || 'Failed to save product. Please check your input and try again.');
    }
  };

  const renderProductItem = (product) => (
    <div className="flex-1 flex items-center gap-4">
      {product.image && (
        <img 
          src={product.image} 
          alt={product.name}
          className="w-16 h-16 object-cover rounded"
        />
      )}
      <div>
        <h3 className="font-medium">{product.name}</h3>
        <p className={`${THEME.COLORS.TEXT.MUTED} text-sm`}>
          Stock: {product.stock_quantity} | Max: {product.max_quantity || 'No limit'} | Price: £{product.price.toFixed(2)}
        </p>
      </div>
    </div>
  );

  if (isFormOpen) {
    return (
      <div className={`${THEME.COLORS.BG.CONTENT.ITEM} p-6 rounded-lg`}>
        <AdminProductForm
          product={selectedProduct}
          onSubmit={handleSubmit}
          onCancel={() => setIsFormOpen(false)}
        />
      </div>
    );
  }

  return (
    <ContentCrudBase
      title="Products"
      items={products}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDelete={handleDelete}
      renderItem={renderProductItem}
      loading={loading}
    />
  );
};

export default AdminProductList;

import { useState, useCallback, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import BasePage from '@shared/layout/BasePage'
import { analytics } from '@shared/services/AnalyticsService'
import { publicGalleryService } from '@shared/services/PublicGalleryService'
import GalleryGrid from '@shared/features/gallery/GalleryGrid'
import GalleryItem from '@shared/features/gallery/GalleryItem'
import ImageModal from '@shared/features/gallery/ImageModal'
import ViewModeToggle from '@shared/features/gallery/ViewModeToggle'
import CarouselView from '@shared/features/gallery/CarouselView'

function GalleryPage() {
  const [currentImageIndex, setCurrentImageIndex] = useState(null)
  const [viewMode, setViewMode] = useState('grid')
  const [error, setError] = useState(null)

  const handleViewModeChange = useCallback((newMode) => {
    try {
      setViewMode(newMode)
      setCurrentImageIndex(null) // Reset image index when switching modes
      setError(null)
    } catch (err) {
      setError('Failed to switch view mode. Please try again.')
      console.error('View mode switch error:', err)
    }
  }, [])

  // Reset image index when leaving carousel view
  // Effect for view mode changes
  useEffect(() => {
    if (viewMode === 'grid') {
      setCurrentImageIndex(null)
    }
    // Track view mode changes
    analytics.trackEvent('gallery', 'view_mode_change', viewMode);
  }, [viewMode]) // Remove currentImageIndex from dependencies

  // Separate effect for tracking modal state if needed
  useEffect(() => {
    if (currentImageIndex !== null) {
      analytics.trackEvent('gallery', 'modal_open');
      return () => {
        analytics.trackEvent('gallery', 'modal_close');
      };
    }
  }, [currentImageIndex]);

  // Track gallery session duration
  useEffect(() => {
    const startTime = Date.now();
    analytics.trackEvent('gallery', 'session_start');
    
    return () => {
      const duration = Date.now() - startTime;
      analytics.trackMetric('gallery_session_duration', duration);
      analytics.trackEvent('gallery', 'session_end');
    };
  }, []);
  
  const { data: galleryItems = [], isLoading, error: queryError } = useQuery({
    queryKey: ['gallery'],
    queryFn: () => publicGalleryService.getAllImages()
  });

  // Handle loading and error states
  if (isLoading) {
    return (
      <BasePage title="Gallery">
        <div className="flex items-center justify-center min-h-[400px]">
          <div className="animate-pulse text-gray-600">Loading gallery...</div>
        </div>
      </BasePage>
    );
  }

  if (queryError) {
    return (
      <BasePage title="Gallery">
        <div className="p-4 bg-red-100 text-red-700 rounded-lg">
          Failed to load gallery images. Please try again later.
        </div>
      </BasePage>
    );
  }

  return (
    <BasePage title="Gallery">
      <ViewModeToggle 
        mode={viewMode} 
        onModeChange={handleViewModeChange} 
      />
      
      {error && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}
      {viewMode === 'grid' ? (
        <GalleryGrid>
          {galleryItems.map((item, index) => (
            <GalleryItem
              key={item.id}
              image={item.image}
              alt={item.alt}
              caption={item.caption}
              index={index}
              onImageClick={(idx) => setCurrentImageIndex(idx)}
            />
          ))}
        </GalleryGrid>
      ) : (
        <CarouselView
          images={galleryItems.map(item => ({
            image: item.image,
            alt: item.alt
          }))}
          currentIndex={currentImageIndex || 0}
          onImageChange={setCurrentImageIndex}
          className="mt-4"
        />
      )}

      {currentImageIndex !== null && ( // Only render when we have a valid index
        <ImageModal
          image={galleryItems[currentImageIndex]?.image}
          alt={galleryItems[currentImageIndex]?.alt}
          caption={galleryItems[currentImageIndex]?.caption}
          isOpen={true}
          onClose={() => {
            console.log('Modal close triggered from GalleryPage'); // Add debug log
            setCurrentImageIndex(null);
          }}
          onNext={() => setCurrentImageIndex(prev => Math.min(prev + 1, galleryItems.length - 1))}
          onPrevious={() => setCurrentImageIndex(prev => Math.max(prev - 1, 0))}
          hasNext={currentImageIndex < galleryItems.length - 1}
          hasPrevious={currentImageIndex > 0}
        />
      )}
    </BasePage>
  )
}

export default GalleryPage

// shared-components/features/admin/dashboard/DashboardCard.jsx

import PropTypes from 'prop-types';
import { THEME, TYPOGRAPHY } from '@shared/constants/designSystem';

export const DashboardCard = ({ title, children, className = '' }) => {
  return (
    <div className={`
      ${THEME.COLORS.BG.CONTENT.ITEM}
      p-6 rounded-lg shadow-sm
      ${className}
    `}>
      {title && (
        <h3 className={`${TYPOGRAPHY.HEADING.H3} ${THEME.COLORS.TEXT.DEFAULT} mb-4`}>
          {title}
        </h3>
      )}
      {children}
    </div>
  );
};

DashboardCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

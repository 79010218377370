import BasePage from '@shared/layout/BasePage'

function HistoryPage() {
  return (
    <BasePage title="History">
      <div className="mx-auto max-w-4xl space-y-8 px-4">
        {/* Introduction section */}
        <section className="prose prose-lg dark:prose-invert">
          <p className="lead">
            Jean Martyn is an internationally acclaimed artist and consummate entertainer who delights audiences worldwide with her exceptional musical talent and engaging performances.
          </p>

          <h2 className="text-2xl font-bold">Early Life & Education</h2>
          <p>
            Jean&apos;s musical journey began at an early age, leading her to achieve two music degrees before she was 21. Her expertise spans multiple instruments, including Hammond, Yamaha, and Korg organs, as well as pipe organs in cathedrals and churches. She is also the proud owner of two Steinway grand pianos.
          </p>

          <h2 className="text-2xl font-bold">Rise to International Recognition</h2>
          <p>
            In 2011, Jean caught the nation&apos;s attention as a finalist on Britain&apos;s Got Talent, which led to performances in major arenas across the UK, Wales, and Scotland. Her talent has since taken her around the globe, performing in seven U.S. states, Berlin, Germany, Switzerland, the United Arab Emirates, and India. She has graced prestigious venues including Ronnie Scott&apos;s in London and numerous UK theatres.
          </p>

          <h2 className="text-2xl font-bold">Notable Achievements</h2>
          <p>
            Jean holds the distinction of being the only woman to record a CD on the famous Wurlitzer organ in the Blackpool Tower ballroom. Her performances have extended to classical organ concerts at Birmingham&apos;s Symphony Hall, Town Hall, and Lichfield Cathedral, the city of her birth.
          </p>
          
          <p>
            Her charitable work has earned recognition from the Not Forgotten Association, leading to performances at St James&apos;s Palace in London over a ten-year period, where she performed for members of the Royal Family.
          </p>

          <h2 className="text-2xl font-bold">Recent Success</h2>
          <p>
            During the pandemic, Jean&apos;s streaming sessions brought her music to a global audience. Her appearance on Holland&apos;s Got Talent further expanded her international following, and she continues to delight thousands of followers across her social media channels with live performances from both her home and concert venues.
          </p>
          
          <p>
            A significant personal milestone came in 2019 when Jean met her husband Hans during a visit to the Netherlands, marking a new chapter in her life.
          </p>

          <h2 className="text-2xl font-bold">Connect with Jean</h2>
          <p>
            You can follow Jean&apos;s musical journey on Facebook, TikTok, Instagram, and YouTube, where she regularly shares live performances and connects with her worldwide fanbase. Her performances consistently earn standing ovations, testament to her ability to deliver exceptional entertainment with consummate professionalism.
          </p>
        </section>
      </div>
    </BasePage>
  )
}

export default HistoryPage
/**
 * Converts decimal amount to minor units (e.g., £12.99 to 1299 pence)
 * For Stripe and database storage
 */
export const toMinorUnits = (amount) => Math.round(amount * 100)

/**
 * Converts minor units back to decimal (e.g., 1299 pence to £12.99)
 * For displaying stored prices
 */
export const fromMinorUnits = (minorUnits) => minorUnits / 100

/**
 * Formats price with currency symbol
 */
export const formatPrice = (price) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(price)
}
// AwardsPage.jsx
import BasePage from '@shared/layout/BasePage'

function AwardsPage() {
  return (
    <BasePage title="Awards & Recognition">
      <div className="mx-auto max-w-4xl px-4">
        <div className="rounded-lg border border-gray-200 bg-white p-6 text-center dark:border-gray-700 dark:bg-gray-800">
          <h2 className="mb-4 text-xl font-semibold text-gray-600 dark:text-gray-300">Coming Soon</h2>
          <p className="text-gray-600 dark:text-gray-300">
            A comprehensive list of Jean&apos;s awards and recognition is being prepared. Please check back soon to learn more about her achievements and accolades.
          </p>
        </div>
      </div>
    </BasePage>
  )
}

export default AwardsPage
import ContentLayout from '@shared/features/admin/content/ContentLayout';
import AdminTourDateList from '@shared/features/admin/content/tours/AdminTourDateList';

const AdminTourPage = () => {
  return (
    <ContentLayout title="Tour Date Management">
      <AdminTourDateList />
    </ContentLayout>
  );
};

export default AdminTourPage;

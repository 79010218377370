// websites/jean-martyn/src/Routes.jsx

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from '@shared/layout/MainLayout';
import { AdminLayout } from '@shared/layout/AdminLayout';
import { LoginForm } from '@shared/features/auth/LoginForm';
import { ProtectedRoute } from '@shared/features/auth/ProtectedRoute';

// Pages
import HomePage from './pages/HomePage';
import AwardsPage from './pages/AwardsPage';
import ShopPage from './pages/ShopPage';
import PaymentConfirmation from './pages/PaymentConfirmationPage';
import GalleryPage from './pages/GalleryPage';
import HistoryPage from './pages/HistoryPage';
import DiscographyPage from './pages/DiscographyPage';
import PressPage from './pages/PressPage';
import ContactPage from './pages/ContactPage';
import TourPage from './pages/TourPage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';

// Admin Pages
import DashboardPage from './pages/admin/DashboardPage';
import AdminTourPage from './pages/admin/content/AdminTourPage';
import AdminGalleryPage from './pages/admin/content/AdminGalleryPage';
import AdminShopPage from './pages/admin/content/AdminShopPage';
import AdminPerformancePage from './pages/admin/AdminPerformancePage';
import OrdersPage from './pages/admin/OrdersPage';

export default function AppRoutes() {
  return (
    <Router basename="/">
      <Routes>
        {/* Public Routes wrapped in MaintenanceRoute */}
        <Route element={<MainLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/awards" element={<AwardsPage />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/payment/confirm" element={<PaymentConfirmation />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/history" element={<HistoryPage />} />
          <Route path="/discography" element={<DiscographyPage />} />
          <Route path="/press" element={<PressPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/tour" element={<TourPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms" element={<TermsPage />} />
        </Route>

        {/* Admin Routes */}
        <Route path="/admin">
          <Route path="login" element={<LoginForm />} />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <AdminLayout>
                  <DashboardPage />
                </AdminLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="tour"
            element={
              <ProtectedRoute>
                <AdminLayout>
                  <AdminTourPage />
                </AdminLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="gallery"
            element={
              <ProtectedRoute>
                <AdminLayout>
                  <AdminGalleryPage />
                </AdminLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="shop"
            element={
              <ProtectedRoute>
                <AdminLayout>
                  <AdminShopPage />
                </AdminLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="performance"
            element={
              <ProtectedRoute>
                <AdminLayout>
                  <AdminPerformancePage />
                </AdminLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="orders"
            element={
              <ProtectedRoute>
                <AdminLayout>
                  <OrdersPage />
                </AdminLayout>
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}
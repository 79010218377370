// DiscographyPage.jsx
import BasePage from '@shared/layout/BasePage'

function DiscographyPage() {
  return (
    <BasePage title="Discography">
      <div className="mx-auto max-w-4xl px-4">
        <div className="rounded-lg border border-gray-200 bg-white p-6 text-center dark:border-gray-700 dark:bg-gray-800">
          <h2 className="mb-4 text-xl font-semibold text-gray-600 dark:text-gray-300">Coming Soon</h2>
          <p className="text-gray-600 dark:text-gray-300">
            Jean&apos;s discography is currently being compiled. Please check back soon, or follow Jean on social media for updates about her recordings and releases.
          </p>
        </div>
      </div>
    </BasePage>
  )
}

export default DiscographyPage
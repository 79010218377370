// shared-components/context/CartContext.jsx

import { useReducer, useEffect, useCallback, useRef, useMemo } from 'react'
import { analytics } from '../services/AnalyticsService'
import PropTypes from 'prop-types'
import { 
  generateSessionId, 
  generateCartItemId,
  validateQuantity,
  mergeCartItems
} from '../utils/ShopUtils'
import { CartContext } from './cart-context'

const CART_STORAGE_KEY = 'jm-shop-cart'

const saveCartToStorage = (cart) => {
  try {
    localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cart))
    analytics.trackEvent('cart', 'save', 'success')
    return true
  } catch (error) {
    analytics.logError(error, { action: 'saveCart' })
    return false
  }
}

const loadCartFromStorage = () => {
  try {
    const cart = JSON.parse(localStorage.getItem(CART_STORAGE_KEY))
    analytics.trackEvent('cart', 'load', cart ? 'success' : 'empty')
    return cart
  } catch (error) {
    analytics.logError(error, { action: 'loadCart' })
    return null
  }
}

const clearCartFromStorage = () => {
  try {
    localStorage.removeItem(CART_STORAGE_KEY)
    analytics.trackEvent('cart', 'clear', 'success')
    return true
  } catch (error) {
    analytics.logError(error, { action: 'clearCart' })
    return false
  }
}

const initialState = {
  items: [],
  lastUpdated: Date.now(),
  sessionId: generateSessionId(),
  isLoading: true,
  error: null,
  lastAction: null,
  undoStack: [],
  shippingAddress: null,
  shippingOption: null,
  paymentStatus: null,
  paymentError: null
}

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE_CART':
      return {
        ...state,
        ...action.payload,
        isLoading: false
      }
    
    case 'ADD_ITEM': {
      const newItems = mergeCartItems(state.items, [action.payload])
      return {
        ...state,
        items: newItems,
        lastUpdated: Date.now()
      }
    }
    
    case 'REMOVE_ITEM':
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
        lastUpdated: Date.now()
      }
    
    case 'UPDATE_QUANTITY': {
      const { itemId, quantity } = action.payload
      return {
        ...state,
        items: state.items.map(item => 
          item.id === itemId
            ? { ...item, quantity: validateQuantity(quantity, item.maxQuantity) }
            : item
        ),
        lastUpdated: Date.now()
      }
    }
    
    case 'BULK_ADD': {
      const newItems = mergeCartItems(state.items, action.payload)
      return {
        ...state,
        items: newItems,
        lastUpdated: Date.now()
      }
    }
    
    case 'BULK_REMOVE':
      return {
        ...state,
        items: state.items.filter(item => !action.payload.includes(item.id)),
        lastUpdated: Date.now()
      }
    
    case 'CLEAR_CART':
      return {
        ...state,
        items: [],
        lastUpdated: Date.now()
      }
    
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }
    
    case 'SET_SHIPPING_ADDRESS':
      return {
        ...state,
        shippingAddress: action.payload,
        lastUpdated: Date.now()
      }
    
    case 'SET_SHIPPING_OPTION':
      return {
        ...state,
        shippingOption: action.payload,
        lastUpdated: Date.now()
      }
    
    case 'SET_PAYMENT_STATUS':
      return {
        ...state,
        paymentStatus: action.payload,
        lastUpdated: Date.now()
      }

    case 'SET_PAYMENT_ERROR':
      return {
        ...state,
        paymentError: action.payload,
        lastUpdated: Date.now()
      }
    
    default:
      return state
  }
}

const usePerformanceTracking = () => {
  const performanceRef = useRef({
    lastOperation: null,
    startTime: null,
    pendingOperations: new Set()
  })

  const trackOperation = useCallback((operation) => {
    if (performanceRef.current.pendingOperations.has(operation)) {
      return
    }

    performanceRef.current.pendingOperations.add(operation)
    const startTime = performance.now()

    requestIdleCallback(() => {
      const duration = performance.now() - startTime
      analytics.trackMetric(`cart_${operation}_duration`, duration)
      performanceRef.current.pendingOperations.delete(operation)
    }, { timeout: 2000 })
  }, [])

  return trackOperation
}

export function CartProvider({ children }) {
  const [state, dispatch] = useReducer(cartReducer, initialState)
  const trackOperation = usePerformanceTracking()
  const shippingOptionTimeoutRef = useRef(null)
  const currentShippingOptionRef = useRef(null)

  useEffect(() => {
    analytics.init()
  }, [])

  useEffect(() => {
    const initializeCart = async () => {
      try {
        trackOperation('init')
        const savedCart = loadCartFromStorage()
        
        dispatch({ 
          type: 'INITIALIZE_CART', 
          payload: savedCart || initialState 
        })
      } catch (error) {
        analytics.logError(error, { context: 'cartInitialization' })
        dispatch({ 
          type: 'SET_ERROR', 
          payload: new Error('Failed to load cart') 
        })
      }
    }

    initializeCart()
  }, [trackOperation])

  useEffect(() => {
    if (state.isLoading) return

    const timeoutId = setTimeout(() => {
      saveCartToStorage({
        items: state.items,
        lastUpdated: state.lastUpdated,
        sessionId: state.sessionId
      })
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [state.items, state.lastUpdated, state.sessionId, state.isLoading])

  useEffect(() => {
    return () => {
      if (shippingOptionTimeoutRef.current) {
        clearTimeout(shippingOptionTimeoutRef.current)
      }
    }
  }, [])

  const actions = useMemo(() => ({
    addItem: (item) => {
      trackOperation('add_item')
      dispatch({ 
        type: 'ADD_ITEM', 
        payload: {
          ...item,
          id: generateCartItemId()
        }
      })
    },

    removeItem: (itemId) => {
      trackOperation('remove_item')
      dispatch({ type: 'REMOVE_ITEM', payload: itemId })
    },

    updateQuantity: (itemId, quantity) => {
      trackOperation('update_quantity')
      dispatch({ 
        type: 'UPDATE_QUANTITY', 
        payload: { itemId, quantity }
      })
    },

    clearCart: () => {
      trackOperation('clear_cart')
      dispatch({ type: 'CLEAR_CART' })
      clearCartFromStorage()
    },

    bulkAdd: (items) => {
      trackOperation('bulk_add')
      dispatch({ type: 'BULK_ADD', payload: items })
    },

    bulkRemove: (itemIds) => {
      trackOperation('bulk_remove')
      dispatch({ type: 'BULK_REMOVE', payload: itemIds })
    },

    setShippingAddress: (address) => {
      trackOperation('set_shipping_address')
      dispatch({ type: 'SET_SHIPPING_ADDRESS', payload: address })
    },

    setShippingOption: (option) => {
      if (currentShippingOptionRef.current === option?.id) {
        return
      }

      currentShippingOptionRef.current = option?.id

      if (shippingOptionTimeoutRef.current) {
        clearTimeout(shippingOptionTimeoutRef.current)
      }

      shippingOptionTimeoutRef.current = setTimeout(() => {
        trackOperation('set_shipping_option')
        shippingOptionTimeoutRef.current = null
      }, 1000)

      dispatch({ type: 'SET_SHIPPING_OPTION', payload: option })
    },

    setPaymentStatus: (status) => {
      trackOperation('set_payment_status')
      dispatch({ type: 'SET_PAYMENT_STATUS', payload: status })
    },

    setPaymentError: (error) => {
      trackOperation('set_payment_error')
      dispatch({ type: 'SET_PAYMENT_ERROR', payload: error })
    }
  }), [trackOperation])

  const value = useMemo(() => ({
    ...state,
    ...actions
  }), [state, actions])

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  )
}

CartProvider.propTypes = {
  children: PropTypes.node.isRequired
}
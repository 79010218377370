// websites/jean-martyn/src/config/gridItems.js

import { CELL_TYPES } from '@shared/features/home/gridConstants';

/**
 * Client-specific grid items with correct image paths
 */
export const jeanMartynGridItems = [
  {
    id: 'awards',
    title: 'Awards',
    description: 'Recognition and achievements throughout an illustrious career',
    image: '/images/homepage/awards.jpg',
    link: '/awards',
    type: CELL_TYPES.TALL
  },
  {
    id: 'gallery',
    title: 'Gallery',
    description: 'A visual journey through performances and memories',
    image: '/images/homepage/gallery.jpg',
    link: '/gallery',
    type: CELL_TYPES.SQUARE
  },
  {
    id: 'history',
    title: 'History',
    description: 'Musical journey and biography',
    image: '/images/homepage/history.jpg',
    link: '/history',
    type: CELL_TYPES.TALL
  },
  {
    id: 'tour',
    title: 'Tour',
    description: 'Times and Places Jean will be',
    image: '/images/homepage/tour.jpg',
    link: '/tour',
    type: CELL_TYPES.TALL
  },
  {
    id: 'discography',
    title: 'Discography',
    description: 'Albums and recordings that have touched hearts worldwide',
    image: '/images/homepage/discography.jpg',
    link: '/discography',
    type: CELL_TYPES.SHORT
  },
  {
    id: 'shop',
    title: 'Shop',
    description: 'Music, merchandise, and more',
    image: '/images/homepage/shop.jpg',
    link: '/shop',
    type: CELL_TYPES.TALL
  },
  {
    id: 'press',
    title: 'Press',
    description: 'News and media coverage',
    image: '/images/homepage/press.jpg',
    link: '/press',
    type: CELL_TYPES.SQUARE
  },
  {
    id: 'contact',
    title: 'Contact',
    description: 'Get in touch for bookings and inquiries',
    image: '/images/homepage/contact.jpg',
    link: '/contact',
    type: CELL_TYPES.SQUARE
  }
];
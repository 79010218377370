// src/pages/TermsPage.jsx
import BasePage from '@shared/layout/BasePage'

function TermsPage() {
  return (
    <BasePage title="Terms of Service">
      <div className="mx-auto max-w-4xl px-4">
        <div className="prose prose-lg dark:prose-invert">
          <h2>Terms of Service</h2>
          <p>Last updated: 6th February 2025</p>

          <h3>Agreement to Terms</h3>
          <p>By accessing this website, you agree to be bound by these Terms of Service. If you disagree with any part of these terms, please do not use our website.</p>

          <h3>Intellectual Property</h3>
          <p>All content on this website, including but not limited to text, images, graphics, and logos, is the property of Jean Martyn or used with permission. This content is protected by copyright and other intellectual property laws.</p>

          <h3>Website Use</h3>
          <p>You may access our website for personal, non-commercial use. This includes:</p>
          <ul>
            <li>Viewing information about performances and events</li>
            <li>Accessing the photo gallery</li>
            <li>Viewing tour dates and venue information</li>
            <li>Accessing contact information</li>
          </ul>

          <h3>External Links and Bookings</h3>
          <p>Our website includes links to external websites for event bookings and venues. We are not responsible for:</p>
          <ul>
            <li>Content on external websites</li>
            <li>Booking processes handled by external platforms</li>
            <li>Changes to event details by venues or organizers</li>
            <li>Availability or pricing of tickets</li>
          </ul>

          <h3>Accuracy of Information</h3>
          <p>While we strive to keep all information current, event details, dates, and venues may change. Please confirm details with the venue or booking platform before making travel arrangements.</p>

          <h3>Changes to Terms</h3>
          <p>We reserve the right to modify these terms at any time. Changes will be effective immediately upon posting to the website.</p>

          <h3>Contact</h3>
          <p>If you have any questions about these Terms of Service, please contact us through the details provided on our Contact page.</p>
        </div>
      </div>
    </BasePage>
  )
}

export default TermsPage
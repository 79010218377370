// OrderService.js
import { getSupabaseClient } from './SupabaseService';

class OrderService {
  constructor() {
    this.tableName = 'orders';
    this.supabase = getSupabaseClient();
  }

  async getAllOrders(page = 1, pageSize = 10) {
    try {
      // Get and log current session and user details
      const { data: { session } } = await this.supabase.auth.getSession();
      console.log('Current session:', {
        hasSession: !!session,
        role: session?.user?.role,
        id: session?.user?.id
      });

      const from = (page - 1) * pageSize;
      const to = from + pageSize - 1;

      // Log query parameters
      console.log('Making orders request:', {
        table: this.tableName,
        range: { from, to },
        pageSize,
        hasAuth: !!session
      });

      // Add error handling for the main query
      const { data, error, count } = await this.supabase
        .from(this.tableName)
        .select(`
          id,
          order_reference,
          customer_email,
          shipping_address,
          shipping_option,
          total_amount,
          vat_amount,
          currency,
          status,
          created_at,
          updated_at,
          order_items!inner (
            id,
            order_id,
            quantity,
            products!inner (
              id,
              name
            )
          )
        `, { count: 'exact' })
        .order('created_at', { ascending: false })
        .range(from, to);

      // Detailed error logging
      if (error) {
        console.error('Supabase query error:', {
          code: error.code,
          message: error.message,
          details: error.details,
          hint: error.hint
        });
        throw error;
      }

      // Log success response
      console.log('Orders query successful:', {
        count,
        receivedOrders: data?.length ?? 0,
        firstOrderId: data?.[0]?.id,
        firstOrder: data?.[0] ? {
          reference: data[0].order_reference,
          email: data[0].customer_email,
          status: data[0].status,
          itemCount: data[0].order_items?.length ?? 0
        } : null,
        error
      });

      return { data, count };

    } catch (error) {
      // Enhanced error logging
      console.error('Error in getAllOrders:', {
        error,
        message: error.message,
        code: error.code,
        details: error?.details,
        stack: error.stack
      });
      throw error;
    }
  }

  async getOrderById(id) {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select(`
          *,
          order_items (
            id,
            order_id,
            product_id,
            quantity,
            price_at_time,
            created_at,
            products (
              id,
              name,
              description,
              price,
              image
            )
          )
        `)
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error fetching order by ID:', {
          orderId: id,
          error: error
        });
        throw error;
      }

      // Log successful fetch
      console.log('Order details fetched:', {
        orderId: id,
        reference: data.order_reference,
        itemCount: data.order_items?.length ?? 0
      });

      return data;
    } catch (error) {
      console.error('Error in getOrderById:', {
        orderId: id,
        error: error
      });
      throw error;
    }
  }

  async updateOrderStatus(id, status) {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .update({ status })
        .eq('id', id)
        .select()
        .single();

      if (error) {
        console.error('Error updating order status:', {
          orderId: id,
          status,
          error: error
        });
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Error in updateOrderStatus:', {
        orderId: id,
        status,
        error: error
      });
      throw error;
    }
  }
}

export const orderService = new OrderService();

// shared-components/context/ProductContext.jsx

import { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { createTrackEvent } from '../utils/analytics.js'
import { ProductContext } from './product-context'
import placeholderImg from '../../websites/jean-martyn/public/images/shop/placeholder.jpg'

export function ProductProvider({ children }) {
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [retryCount, setRetryCount] = useState(0)
  const MAX_RETRIES = 3
  const [viewMode, setViewMode] = useState('grid')
  const [filters, setFilters] = useState({
    categories: [],
    inStockOnly: false,
    priceRange: { min: 0, max: Infinity },
    searchQuery: ''
  })
  const [sortConfig, setSortConfig] = useState({
    field: 'name',
    direction: 'asc'
  })

  const LOAD_TIMEOUT = 10000 // 10 seconds timeout
  const TRANSITION_DURATION = 300 // match duration with CSS

  const trackEvent = useCallback((eventName, data) => {
    createTrackEvent()(eventName, data)
  }, [])

  const fetchProducts = useCallback(async () => {
    const metrics = {
      startTime: performance.now(),
      loadingDuration: 0,
      transitionDuration: 0,
      success: false
    }
    
    try {
      setIsLoading(true)
      setError(null)
      const startTime = Date.now()
      
      // Create a timeout promise
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Loading timeout exceeded')), LOAD_TIMEOUT)
      })
      
      const { adminProductService } = await import('../services/AdminProductService')
      const products = await adminProductService.getAllProducts()
      
      // Transform the data to match our frontend schema
      const transformedProducts = products.map(product => ({
        id: product.id,
        name: product.name,
        description: product.description,
        price: product.price,
        image: product.image || placeholderImg,
        inStock: product.in_stock,
        categories: product.product_categories ? [product.product_categories.name] : [],
        maxQuantity: product.max_quantity
      }))
      
      // Race between fetch and timeout
      const response = await Promise.race([Promise.resolve(transformedProducts), timeoutPromise])
      // Ensure minimal loading time to prevent flashing
      const elapsed = Date.now() - startTime
      if (elapsed < TRANSITION_DURATION) {
        await new Promise(resolve => setTimeout(resolve, TRANSITION_DURATION - elapsed))
      }
      
      metrics.loadingDuration = performance.now() - metrics.startTime
      setProducts(response)
      metrics.success = true
      trackEvent('products_loaded', { 
        count: response.length,
        loadingDuration: metrics.loadingDuration,
        transitionDuration: Date.now() - startTime
      })
    } catch (err) {
      const errorMessage = err.message === 'Loading timeout exceeded' 
        ? 'Products took too long to load. Please try again.'
        : 'Failed to load products. Please try again later.'
      
      setError({ 
        message: errorMessage, 
        originalError: err,
        canRetry: retryCount < MAX_RETRIES
      })
      trackEvent('products_load_error', { 
        error: err.message,
        retryCount,
        timeout: err.message === 'Loading timeout exceeded'
      })
      console.error('Failed to fetch products:', err)
      setRetryCount(prev => prev + 1)
    } finally {
      setIsLoading(false)
    }
  }, [trackEvent, retryCount])


  const setViewModeWithAnalytics = useCallback((newMode) => {
    trackEvent('view_mode_changed', { newMode })
    setViewMode(newMode)
  }, [trackEvent])

  const filteredProducts = useCallback(() => {
    if (!products.length) return [];
    
    return products
      .filter(product => {
        if (filters.inStockOnly && !product.inStock) return false
        if (filters.categories.length && !filters.categories.some(c => product.categories.includes(c))) return false
        if (filters.searchQuery) {
          const query = filters.searchQuery.toLowerCase()
          return product.name.toLowerCase().includes(query) || 
                 product.description.toLowerCase().includes(query)
        }
        return true
      })
      .sort((a, b) => {
        const aVal = a[sortConfig.field]
        const bVal = b[sortConfig.field]
        const modifier = sortConfig.direction === 'asc' ? 1 : -1
        return aVal > bVal ? modifier : -modifier
      })
  }, [products, filters, sortConfig])

  const resetError = useCallback(() => {
    setError(null)
    setRetryCount(0)
    trackEvent('error_state_reset')
  }, [trackEvent])

  // Initial load effect
  useEffect(() => {
    let mounted = true;

    const loadProducts = async () => {
      if (mounted) {
        await fetchProducts();
      }
    };

    loadProducts();

    return () => {
      mounted = false;
    };
  }, [fetchProducts]);

  const value = {
    products: filteredProducts(),
    isLoading,
    error,
    viewMode,
    filters,
    sortConfig,
    setViewMode: setViewModeWithAnalytics,
    setFilters,
    setSortConfig,
    refetch: fetchProducts,
    resetError,
    trackEvent
  }

  return (
    <ProductContext.Provider value={value}>
      {children}
    </ProductContext.Provider>
  )
}

ProductProvider.propTypes = {
  children: PropTypes.node.isRequired
}


import { Component } from 'react'
import PropTypes from 'prop-types'
import { THEME } from '../../../constants/designSystem'
import { getSupportInfo } from '../../../utils/CheckoutUtils'
import { analytics } from '../../../services/AnalyticsService'

class PaymentErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    analytics.logError(error, {
      context: 'payment_error_boundary',
      ...errorInfo
    })
  }

  render() {
    if (this.state.hasError) {
      const supportInfo = getSupportInfo()
      
      return (
        <div className="p-6 rounded-lg border border-red-200 dark:border-red-800 bg-red-50 dark:bg-red-900/10">
          <h3 className="text-lg font-semibold mb-4 text-red-700 dark:text-red-400">
            Payment System Error
          </h3>
          <p className="mb-4 text-red-600 dark:text-red-400">
            We&apos;re sorry, but something went wrong with the payment system.
            Please try again or contact our support team for assistance.
          </p>
          
          <div className="mb-6">
            <h4 className="font-medium mb-2">Contact Support:</h4>
            <ul className="space-y-1 text-sm">
              <li>Email: {supportInfo.email}</li>
              <li>Phone: {supportInfo.phone}</li>
              <li>Hours: {supportInfo.hours}</li>
            </ul>
          </div>

          <div className="flex gap-4">
            <button
              onClick={() => {
                this.setState({ hasError: false, error: null })
                this.props.onReset?.()
              }}
              className={`
                px-4 py-2 rounded-lg
                ${THEME.COLORS.BG.PRIMARY}
                ${THEME.COLORS.TEXT.ON_PRIMARY}
                hover:opacity-90 transition-opacity
              `}
            >
              Try Again
            </button>
            
            <button
              onClick={() => window.location.href = '/shop'}
              className={`
                px-4 py-2 rounded-lg
                border border-gray-300 dark:border-gray-700
                hover:bg-gray-50 dark:hover:bg-gray-800
                transition-colors
              `}
            >
              Return to Shop
            </button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

PaymentErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  onReset: PropTypes.func
}

export default PaymentErrorBoundary

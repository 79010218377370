import { analytics } from '../services/AnalyticsService.js'

const ORDER_PREFIX = 'JM'

/**
 * Generate unique order reference
 */
export const generateOrderReference = () => {
  const timestamp = Date.now().toString(36)
  const sequence = Math.random().toString(36).substring(2, 5)
  return `${ORDER_PREFIX}-${timestamp}-${sequence}`.toUpperCase()
}

// Shipping options with fixed rates
export const SHIPPING_OPTIONS = {
  GB: [
    {
      id: 'uk-standard',
      name: 'Standard Delivery',
      price: 3.99,
      estimatedDays: { min: 3, max: 5 },
      description: '3-5 working days'
    },
    {
      id: 'uk-express',
      name: 'Express Delivery',
      price: 6.99,
      estimatedDays: { min: 1, max: 2 },
      description: '1-2 working days'
    }
  ],
  EU: [
    {
      id: 'eu-standard',
      name: 'Standard International',
      price: 9.99,
      estimatedDays: { min: 5, max: 7 },
      description: '5-7 working days'
    },
    {
      id: 'eu-express',
      name: 'Express International',
      price: 14.99,
      estimatedDays: { min: 3, max: 4 },
      description: '3-4 working days'
    }
  ]
}

// Common payment error messages
export const PAYMENT_ERRORS = {
  card_error: {
    message: 'There was an issue with your card. Please check the details and try again.',
    recoverable: true
  },
  processing_error: {
    message: 'There was an issue processing your payment. Please try again.',
    recoverable: true
  },
  authentication_error: {
    message: 'Payment authentication failed. Please try again.',
    recoverable: true
  }
}

/**
 * Get shipping options based on country code
 */
export const getShippingOptions = (countryCode) => 
  countryCode === 'GB' ? SHIPPING_OPTIONS.GB : SHIPPING_OPTIONS.EU

/**
 * Handle payment errors and track analytics
 */
export const handlePaymentError = (error) => {
  analytics.logError(error, { 
    context: 'payment', 
    errorType: error.type 
  })

  return PAYMENT_ERRORS[error.type] || {
    message: 'An unexpected error occurred. Please try again.',
    recoverable: true
  }
}

/**
 * Get support contact information
 */
export const getSupportInfo = () => ({
  email: 'support@jeanmartyn.com',
  phone: '+44 (0)20 1234 5678',
  hours: '9am-5pm GMT, Monday-Friday'
})

/**
 * Calculate estimated delivery dates
 */
export const calculateDeliveryDates = (shippingOption) => {
  const today = new Date()
  const minDate = new Date(today)
  const maxDate = new Date(today)
  
  minDate.setDate(today.getDate() + shippingOption.estimatedDays.min)
  maxDate.setDate(today.getDate() + shippingOption.estimatedDays.max)
  
  return { minDate, maxDate }
}

/**
 * Format a date for display
 */
export const formatDeliveryDate = (date) => {
  return new Intl.DateTimeFormat('en-GB', {
    weekday: 'long',
    day: 'numeric',
    month: 'long'
  }).format(date)
}

/**
 * Send order confirmation email
 */
export const sendOrderConfirmationEmail = async (orderDetails) => {
  try {
    const response = await fetch('/api/send-order-confirmation', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(orderDetails)
    })

    if (!response.ok) throw new Error('Failed to send order confirmation email')

    analytics.trackEvent('order_confirmation_email_sent', {
      orderId: orderDetails.orderReference
    })

    return true
  } catch (error) {
    analytics.logError(error, { 
      context: 'orderConfirmationEmail',
      orderId: orderDetails.orderReference 
    })
    throw error
  }
}
// shared-components/content/ContentBlock.jsx

import PropTypes from 'prop-types'
import { CONTENT_TYPES } from '../constants/contentTypes.js'
import { TYPOGRAPHY, SPACING } from '../constants/designSystem'

const ContentBlock = ({ type, children, className = '' }) => {
  const baseClasses = `${SPACING.DEFAULT} last:mb-0`
  
  const typeClasses = {
    [CONTENT_TYPES.TEXT]: TYPOGRAPHY.PROSE.DEFAULT,
    [CONTENT_TYPES.IMAGE]: 'max-w-full',
    [CONTENT_TYPES.MIXED]: TYPOGRAPHY.PROSE.DEFAULT
  }

  return (
    <div className={`${baseClasses} ${typeClasses[type]} ${className}`}>
      {children}
    </div>
  )
}

ContentBlock.propTypes = {
  type: PropTypes.oneOf(Object.values(CONTENT_TYPES)).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default ContentBlock

// shared-components/features/admin/content/ContentNavigation.jsx

import { NavLink } from 'react-router-dom';
import { TYPOGRAPHY, THEME } from '../../../constants/designSystem';

const navItems = [
  { path: '/admin/tour', label: 'Tour Dates' },
  { path: '/admin/gallery', label: 'Gallery' },
  { path: '/admin/shop', label: 'Shop' }
];

const ContentNavigation = () => {
  return (
    <nav className="bg-gray-50 dark:bg-gray-800 rounded-lg p-4">
      <h2 className={`${TYPOGRAPHY.HEADING.H3} mb-4`}>Content Management</h2>
      <ul className="space-y-2">
        {navItems.map(({ path, label }) => (
          <li key={path}>
            <NavLink
              to={path}
              className={({ isActive }) =>
                `block p-2 rounded-md ${
                  isActive
                    ? `${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY}`
                    : `${THEME.COLORS.TEXT.DEFAULT} hover:${THEME.COLORS.BG.MUTED}`
                }`
              }
            >
              {label}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default ContentNavigation;

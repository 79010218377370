// shared-components/features/admin/dashboard/QuickAccessGrid.jsx

import { Link } from 'react-router-dom';
import { THEME, TYPOGRAPHY } from '../../../constants/designSystem';
import {
  PhotoIcon,
  ShoppingBagIcon,
  CalendarIcon,
  NewspaperIcon,
} from '@heroicons/react/24/outline';

const quickAccessItems = [
  {
    title: 'Gallery',
    description: 'Manage photo gallery content',
    icon: PhotoIcon,
    to: '/admin/gallery'
  },
  {
    title: 'Shop',
    description: 'Manage shop products',
    icon: ShoppingBagIcon,
    to: '/admin/shop'
  },
  {
    title: 'Orders',
    description: 'View customer orders',
    icon: ShoppingBagIcon,
    to: '/admin/orders'
  },
  {
    title: 'Tour Dates',
    description: 'Update tour schedule',
    icon: CalendarIcon,
    to: '/admin/tour'
  },
  {
    title: 'Press',
    description: 'Manage press releases',
    icon: NewspaperIcon,
    to: '/admin/press'
  }
];

export const QuickAccessGrid = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-4">
      {quickAccessItems.map((item) => {
        const Icon = item.icon;
        return (
          <Link
            key={item.title}
            to={item.to}
            className={`
              p-6 rounded-lg shadow-sm
              ${THEME.COLORS.BG.CONTENT.ITEM}
              ${THEME.COLORS.HOVER.BG.DEFAULT}
              transition-colors duration-200
            `}
          >
            <Icon className={`w-8 h-8 mb-4 ${THEME.COLORS.TEXT.MUTED}`} />
            <h3 className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT} font-semibold mb-2`}>
              {item.title}
            </h3>
            <p className={`${TYPOGRAPHY.BODY.SMALL} ${THEME.COLORS.TEXT.MUTED}`}>
              {item.description}
            </p>
          </Link>
        )
      })}
    </div>
  );
};

// shared-components/features/admin/AdminMobileNav.jsx

import PropTypes from 'prop-types';
import { AdminNav } from './AdminNav';
import { THEME, ANIMATION, OPACITY, TYPOGRAPHY } from '@shared/constants/designSystem';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const AdminMobileNav = ({ isOpen, onClose }) => {
  return (
    <>
      {/* Backdrop */}
      <div
        className={`
          fixed inset-0 bg-black/30 dark:bg-black/50 z-40
          ${isOpen ? OPACITY.FULL : OPACITY.NONE}
          ${isOpen ? 'pointer-events-auto' : 'pointer-events-none'}
          ${ANIMATION.DURATION.FAST} transition-opacity
        `}
        onClick={onClose}
      />

      {/* Sliding menu */}
      <div
        className={`
          fixed inset-y-0 left-0 w-[280px] z-50 overflow-y-auto
          transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}
          ${ANIMATION.DURATION.DEFAULT} transition-transform
          ${THEME.COLORS.BG.DEFAULT}
          border-r ${THEME.COLORS.BORDER.DEFAULT}
        `}
      >
        <div className={`flex items-center justify-between h-16 px-4 border-b ${THEME.COLORS.BORDER.DEFAULT}`}>
          <h2 className={`${TYPOGRAPHY.BODY.DEFAULT} font-semibold ${THEME.COLORS.TEXT.DEFAULT}`}>
            Menu
          </h2>
          <button
            onClick={onClose}
            className={`p-2 rounded-md ${THEME.COLORS.TEXT.MUTED} hover:bg-gray-100 dark:hover:bg-gray-800`}
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
        <div className="p-4">
          <AdminNav />
        </div>
      </div>
    </>
  );
};

AdminMobileNav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

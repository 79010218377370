import { getSupabaseClient } from './SupabaseService.js';

export class PerformanceDataService {
  constructor() {
    this.supabase = getSupabaseClient();
    this.batchSize = 50;
    this.flushInterval = 30000;
    this.metricsQueue = [];
    this.eventsQueue = [];
    this.errorsQueue = [];
    this.isPaused = true;
    this.flushIntervalId = null;
    this.initializeFlushInterval();
  }

  initializeFlushInterval() {
    if (this.flushIntervalId) {
      clearInterval(this.flushIntervalId);
    }
    this.flushIntervalId = setInterval(() => {
      if (!this.isPaused) {
        this.flushMetrics();
        this.flushEvents();
        this.flushErrors();
      }
    }, this.flushInterval);
  }

  async flushMetrics() {
    if (this.metricsQueue.length === 0) return;

    const metrics = [...this.metricsQueue];
    this.metricsQueue = [];

    try {
      const { error } = await this.supabase
        .from('performance_metrics')
        .insert(metrics);

      if (error) throw error;
    } catch (error) {
      console.error('Error flushing metrics:', error);
      // Re-queue failed metrics
      this.metricsQueue.push(...metrics);
    }
  }

  async flushEvents() {
    if (this.eventsQueue.length === 0) return;

    const events = [...this.eventsQueue];
    this.eventsQueue = [];

    try {
      const { error } = await this.supabase
        .from('analytics_events')
        .insert(events);

      if (error) throw error;
    } catch (error) {
      console.error('Error flushing events:', error);
      this.eventsQueue.push(...events);
    }
  }

  async flushErrors() {
    if (this.errorsQueue.length === 0) return;

    const errors = [...this.errorsQueue];
    this.errorsQueue = [];

    try {
      const { error } = await this.supabase
        .from('error_logs')
        .insert(errors);

      if (error) throw error;
    } catch (error) {
      console.error('Error flushing error logs:', error);
      this.errorsQueue.push(...errors);
    }
  }

  async insertMetric(name, value, metadata = {}) {
    if (!this.isPaused) {
      this.metricsQueue.push({
        metric_name: name,
        value,
        metadata
      });

      if (this.metricsQueue.length >= this.batchSize) {
        await this.flushMetrics();
      }
    }
  }

  pauseMetrics() {
    this.isPaused = true;
  }

  resumeMetrics() {
    this.isPaused = false;
    // Flush any queued items immediately when resuming
    this.flushMetrics();
    this.flushEvents();
    this.flushErrors();
  }

  async insertEvent(category, action, label, value, priority, metadata = {}) {
    if (this.isPaused) return;
    // Ensure value is always a valid number
    let numericValue = 0;
    
    if (value !== null && value !== undefined) {
      if (typeof value === 'number') {
        numericValue = isNaN(value) ? 0 : value;
      } else if (typeof value === 'object') {
        // Extract first available numeric value from object
        const numericFields = ['count', 'value', 'duration', 'time', 'size'];
        for (const field of numericFields) {
          if (typeof value[field] === 'number' && !isNaN(value[field])) {
            numericValue = value[field];
            break;
          }
        }
      } else if (typeof value === 'string') {
        // Try to parse string as number
        const parsed = parseFloat(value);
        if (!isNaN(parsed)) {
          numericValue = parsed;
        }
      }
    }

    const eventData = {
      category,
      action,
      label,
      value: numericValue, // Will always be a valid number
      priority,
      metadata: JSON.stringify({
        ...metadata,
        originalValue: value
      })
    };

    this.eventsQueue.push(eventData);

    if (this.eventsQueue.length >= this.batchSize) {
      await this.flushEvents();
    }
  }

  async insertError(message, stack, context = {}) {
    if (this.isPaused) return;
    this.errorsQueue.push({
      message,
      stack,
      context,
      url: window.location.href,
      user_agent: navigator.userAgent
    });

    if (this.errorsQueue.length >= this.batchSize) {
      await this.flushErrors();
    }
  }

  async getMetrics(metricName, timeWindow) {
    const now = new Date();
    const startTime = new Date(now.getTime() - timeWindow);

    const query = this.supabase
      .from('performance_metrics')
      .select('*')
      .eq('metric_name', metricName)
      .gte('timestamp', startTime.toISOString())
      .order('timestamp', { ascending: true });

    const { data, error } = await query;

    if (error) {
      console.error('Error fetching metrics:', error);
      return [];
    }

    return data;
  }

  async getEvents(category, timeWindow) {
    const now = new Date();
    const startTime = new Date(now.getTime() - timeWindow);

    const query = this.supabase
      .from('analytics_events')
      .select('*')
      .eq('category', category)
      .gte('timestamp', startTime.toISOString())
      .order('timestamp', { ascending: true });

    const { data, error } = await query;

    if (error) {
      console.error('Error fetching events:', error);
      return [];
    }

    return data;
  }

  async getErrors(timeWindow) {
    const now = new Date();
    const startTime = new Date(now.getTime() - timeWindow);

    const query = this.supabase
      .from('error_logs')
      .select('*')
      .gte('timestamp', startTime.toISOString())
      .order('timestamp', { ascending: true });

    const { data, error } = await query;

    if (error) {
      console.error('Error fetching errors:', error);
      return [];
    }

    return data;
  }
}

export const performanceData = new PerformanceDataService();

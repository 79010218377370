// src/pages/ShopPage.jsx
import BasePage from '@shared/layout/BasePage'
import { ProductProvider } from '@shared/context/ProductContext'
import { CartProvider } from '@shared/context/CartContext'
import ProductGridControls from '@shared/features/shop/products/ProductGridControls'
import ProductGrid from '@shared/features/shop/products/ProductGrid'
import CartSummary from '@shared/features/shop/cart/CartSummary'
import { useCart } from '@shared/hooks/useCart'

function ShopContent() {
  const { items } = useCart()
  const hasItems = items.length > 0

  return (
    <div className={`flex flex-col lg:flex-row gap-4 ${hasItems ? '' : 'lg:justify-center'}`}>
      <div className={hasItems ? 'lg:w-1/2' : 'w-full max-w-4xl'}>
        <ProductGridControls />
        <ProductGrid />
      </div>
      {hasItems && (
        <div className="lg:sticky lg:top-4 lg:h-fit lg:w-1/2">
          <CartSummary />
        </div>
      )}
    </div>
  )
}

function ShopPage() {
  return (
    <BasePage title="Shop">
      <CartProvider>
        <ProductProvider>
          <ShopContent />
        </ProductProvider>
      </CartProvider>
    </BasePage>
  )
}

export default ShopPage
// shared-components/features/admin/AdminHeader.jsx

import PropTypes from 'prop-types';
import { AdminUserInfo } from './AdminUserInfo';
import { THEME } from '@shared/constants/designSystem';
import { Bars3Icon } from '@heroicons/react/24/outline';
import ThemeToggle from '@shared/ui/ThemeToggle'; // Add this import

export const AdminHeader = ({ onLogout, onMenuClick }) => {
  return (
    <header className={`${THEME.COLORS.BG.DEFAULT} shadow-sm sticky top-0 z-[60]`}>
      <div className="w-full h-16">
        <div className="h-full px-4 flex items-center">
          {/* Left side */}
          <button
            onClick={onMenuClick}
            className={`md:hidden p-2 rounded-md ${THEME.COLORS.HOVER.BG.DEFAULT}`}
          >
            <Bars3Icon className="h-6 w-6" />
          </button>
          <div className="md:hidden ml-4">
            <h1 className={`text-xl font-bold ${THEME.COLORS.TEXT.DEFAULT}`}>
              Admin Panel
            </h1>
          </div>
          
          {/* Spacer */}
          <div className="flex-1" />
          
          {/* Right side */}
          <div className="flex items-center gap-4">
            <ThemeToggle /> {/* Add ThemeToggle here */}
            <AdminUserInfo />
            <button
              onClick={onLogout}
              className={`px-4 py-2 text-sm rounded-md
                ${THEME.COLORS.BG.SECONDARY} 
                ${THEME.COLORS.TEXT.ON_SECONDARY}
                ${THEME.COLORS.HOVER.BG.SECONDARY}
                transition-colors duration-200`}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

AdminHeader.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onMenuClick: PropTypes.func.isRequired
};
// shared-components/features/admin/content/StatusIndicator.jsx

import PropTypes from 'prop-types';
import { TYPOGRAPHY } from '../../../constants/designSystem';

const statusConfig = {
  draft: {
    label: 'Draft',
    className: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
  },
  published: {
    label: 'Published',
    className: 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
  },
  past: {
    label: 'Past',
    className: 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200'
  },
  pending: {
    label: 'Pending',
    className: 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200'
  }
};

const StatusIndicator = ({ status }) => {
  const config = statusConfig[status?.toLowerCase()] || statusConfig.draft;

  return (
    <span className={`${config.className} ${TYPOGRAPHY.BODY.SMALL} px-2 py-1 rounded-full`}>
      {config.label}
    </span>
  );
};

StatusIndicator.propTypes = {
  status: PropTypes.oneOf(['draft', 'published', 'past', 'pending']).isRequired
};

export default StatusIndicator;

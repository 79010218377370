import { v4 as uuidv4 } from 'uuid'

// Constants
const LOW_STOCK_THRESHOLD = 5

/**
 * Get product stock status with display text and styling
 */
export const getStockStatus = (stockLevel) => {
  if (stockLevel <= 0) {
    return { 
      text: 'Out of Stock', 
      className: 'text-red-600' 
    }
  }
  if (stockLevel <= LOW_STOCK_THRESHOLD) {
    return { 
      text: `Low Stock (${stockLevel} left)`, 
      className: 'text-amber-600' 
    }
  }
  return { 
    text: 'In Stock', 
    className: 'text-green-600' 
  }
}

/**
 * Calculate total price for cart items
 */
export const calculateCartTotal = (items) => {
  return items.reduce((total, item) => total + (item.price * item.quantity), 0)
}



/**
 * Filter products based on search and category
 */
export const filterProducts = (products, { searchQuery = '', categories = [] }) => {
  return products.filter(product => {
    // Category filter
    if (categories.length > 0 && 
        !product.categories.some(c => categories.includes(c))) {
      return false
    }
    
    // Search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase()
      const searchText = `
        ${product.name} 
        ${product.description} 
        ${product.tags?.join(' ') || ''}
      `.toLowerCase()
      
      return searchText.includes(query)
    }
    
    return true
  })
}

export const generateSessionId = () => uuidv4()
export const generateCartItemId = () => uuidv4()

export const validateQuantity = (quantity, maxQuantity = 10) => {
  const parsedQuantity = parseInt(quantity)
  if (isNaN(parsedQuantity) || parsedQuantity < 1) return 1
  if (maxQuantity && parsedQuantity > maxQuantity) return maxQuantity
  return parsedQuantity
}

export const mergeCartItems = (existingItems, newItems) => {
  const mergedItems = [...existingItems]
  
  newItems.forEach(newItem => {
    const existingIndex = mergedItems.findIndex(
      item => item.productId === newItem.productId && 
              item.variantId === newItem.variantId
    )
    
    if (existingIndex >= 0) {
      mergedItems[existingIndex].quantity = validateQuantity(
        mergedItems[existingIndex].quantity + newItem.quantity,
        mergedItems[existingIndex].maxQuantity
      )
    } else {
      mergedItems.push({
        ...newItem,
        id: generateCartItemId(),
        quantity: validateQuantity(newItem.quantity, newItem.maxQuantity)
      })
    }
  })
  
  return mergedItems
}

/**
 * Get appropriate class names for product grid based on view mode
 */
export const getProductGridClasses = (viewMode) => {
  const baseClasses = 'w-full gap-4 animate-fadeIn'
  
  if (viewMode === 'list') {
    return `${baseClasses} flex flex-col`
  }
  
  return `${baseClasses} grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8`
}

/**
 * Get appropriate class names for product card based on view mode
 */
export const getProductCardClasses = (viewMode) => {
  const baseClasses = `
    relative overflow-hidden rounded-lg shadow-md 
    bg-white dark:bg-gray-800
    transition-transform duration-200 
    hover:shadow-lg hover:scale-[1.02]
  `
  
  if (viewMode === 'list') {
    return `${baseClasses} flex gap-4`
  }
  
  return baseClasses
}
// shared-components/features/gallery/ImageModal.jsx

import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { analytics } from '../../services/AnalyticsService'

const ImageModal = ({ 
  image, 
  alt, 
  caption,
  isOpen, 
  onClose,
  onNext,
  onPrevious,
  hasNext,
  hasPrevious
}) => {
  useEffect(() => {
    const handleKeyboard = (e) => {
      if (e.key === 'Escape') {
        onClose();
        analytics.trackEvent('gallery', 'modal_close', 'keyboard');
      }
      if (e.key === 'ArrowRight' && hasNext) {
        onNext();
        analytics.trackEvent('gallery', 'navigation', 'keyboard_next');
      }
      if (e.key === 'ArrowLeft' && hasPrevious) {
        onPrevious();
        analytics.trackEvent('gallery', 'navigation', 'keyboard_previous');
      }
    }
    
    if (isOpen) {
      document.addEventListener('keydown', handleKeyboard)
      document.body.style.overflow = 'hidden'
    }
    
    return () => {
      document.removeEventListener('keydown', handleKeyboard)
      document.body.style.overflow = 'unset'
    }
  }, [isOpen, onClose, onNext, onPrevious, hasNext, hasPrevious])

  if (!isOpen) return null

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 backdrop-blur-sm p-4 sm:p-6 md:p-8"
      onClick={(e) => {
        console.log('Outer container clicked'); // Add debug log
        onClose();
      }}
    >
      <div 
        className="relative w-full max-w-[95vw] md:max-w-[90vw] lg:max-w-[85vw] max-h-[95vh] md:max-h-[90vh] flex flex-col items-center"
        onClick={(e) => {
          console.log('Inner container clicked'); // Add debug log
          e.stopPropagation();
        }}
      >
        <img
          src={image}
          alt={alt}
          className="max-w-full max-h-[85vh] md:max-h-[80vh] object-contain rounded-lg"
        />
        {caption && (
          <div className="mt-4 text-white text-center max-w-2xl">
            {caption}
          </div>
        )}
        {/* Navigation buttons */}
        {hasPrevious && (
          <button
            onClick={(e) => { // Added e.stopPropagation()
              e.stopPropagation();
              onPrevious();
            }}
            className="absolute left-2 sm:left-4 p-2 sm:p-3 text-white hover:text-gray-300 transition-colors z-10 
                     bg-black/20 hover:bg-black/40 rounded-full backdrop-blur-sm
                     touch-manipulation"
            aria-label="Previous image"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
        )}
        {hasNext && (
          <button
            onClick={(e) => { // Added e.stopPropagation()
              e.stopPropagation();
              onNext();
            }}
            className="absolute right-12 sm:right-16 p-2 sm:p-3 text-white hover:text-gray-300 transition-colors z-10
                     bg-black/20 hover:bg-black/40 rounded-full backdrop-blur-sm
                     touch-manipulation"
            aria-label="Next image"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        )}
        <button
          onClick={(e) => { // Added e.stopPropagation()
            e.stopPropagation();
            onClose();
          }}
          className="absolute top-2 sm:top-4 right-2 sm:right-4 p-2 sm:p-3 text-white hover:text-gray-300 transition-colors z-10
                   bg-black/20 hover:bg-black/40 rounded-full backdrop-blur-sm
                   touch-manipulation"
          aria-label="Close modal"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  )
}

ImageModal.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  hasNext: PropTypes.bool,
  hasPrevious: PropTypes.bool
}

export default ImageModal

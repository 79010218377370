const STALE_DATA_THRESHOLD = 7 * 24 * 60 * 60 * 1000; // 7 days
const STORAGE_WARNING_THRESHOLD = 0.8; // 80% of quota

// Data priority levels
const PRIORITY = {
  CRITICAL: 'critical',   // Never auto-clean (cart data, user preferences)
  HIGH: 'high',          // Clean last (session data)
  MEDIUM: 'medium',      // Clean after low (cached UI state)
  LOW: 'low'            // Clean first (analytics, logs)
};

export class StorageManager {
  constructor(storageKey) {
    this.storageKey = storageKey;
    this.broadcastChannel = typeof BroadcastChannel !== 'undefined' 
      ? new BroadcastChannel('cart-sync')
      : null;
  }

  async hasQuota() {
    if (navigator.storage && navigator.storage.estimate) {
      const { usage, quota } = await navigator.storage.estimate();
      const usageRatio = usage / quota;
      
      // Trigger cleanup if we're near threshold
      if (usageRatio >= STORAGE_WARNING_THRESHOLD) {
        await this.cleanup();
        // Re-check after cleanup
        const { usage: newUsage, quota: newQuota } = await navigator.storage.estimate();
        return newUsage + 1024 < newQuota; // Leave 1KB buffer
      }
      
      return usage + 1024 < quota; // Leave 1KB buffer
    }
    return true; // Fallback for browsers without Storage API
  }

  async save(data, priority = PRIORITY.MEDIUM) {
    try {
      const hasSpace = await this.hasQuota();
      if (!hasSpace) {
        throw new Error('Storage quota exceeded. Unable to save data even after cleanup.');
      }

      const storageData = {
        data,
        timestamp: Date.now(),
        version: '1.0',
        priority
      };

      localStorage.setItem(this.storageKey, JSON.stringify(storageData));
      this.broadcastUpdate(storageData);
      return true;
    } catch (error) {
      console.error('Storage save failed:', error);
      return false;
    }
  }

  load() {
    try {
      const stored = localStorage.getItem(this.storageKey);
      if (!stored) return null;

      const storageData = JSON.parse(stored);
      const age = Date.now() - storageData.timestamp;
      
      // Only clean stale non-critical data
      if (age > STALE_DATA_THRESHOLD && storageData.priority !== PRIORITY.CRITICAL) {
        this.cleanup();
        return null;
      }

      return storageData.data;
    } catch (error) {
      console.error('Storage load failed:', error);
      return null;
    }
  }

  async cleanup() {
    try {
      const keys = Object.keys(localStorage);
      const now = Date.now();
      let spaceCleaned = 0;
      
      // Group items by priority
      const itemsByPriority = {
        [PRIORITY.LOW]: [],
        [PRIORITY.MEDIUM]: [],
        [PRIORITY.HIGH]: [],
        [PRIORITY.CRITICAL]: []
      };

      // First pass: categorize items
      for (const key of keys) {
        try {
          const stored = localStorage.getItem(key);
          if (!stored) continue;

          const data = JSON.parse(stored);
          const priority = data.priority || PRIORITY.MEDIUM;
          const itemSize = stored.length * 2;
          
          itemsByPriority[priority].push({
            key,
            data,
            size: itemSize,
            age: now - data.timestamp
          });
        } catch (_) {
          // If item can't be parsed, treat as LOW priority
          itemsByPriority[PRIORITY.LOW].push({
            key,
            size: (localStorage.getItem(key) || '').length * 2,
            age: Infinity
          });
        }
      }

      // Second pass: clean up by priority
      const cleanupByPriority = async (items, isStaleOnly = false) => {
        items.sort((a, b) => b.age - a.age); // Oldest first
        
        for (const item of items) {
          if (!isStaleOnly || item.age > STALE_DATA_THRESHOLD) {
            localStorage.removeItem(item.key);
            spaceCleaned += item.size;
          }
        }
      };

      // Clean up in priority order
      await cleanupByPriority(itemsByPriority[PRIORITY.LOW]);
      await cleanupByPriority(itemsByPriority[PRIORITY.MEDIUM], true);
      await cleanupByPriority(itemsByPriority[PRIORITY.HIGH], true);
      // CRITICAL items are never auto-cleaned

      if (spaceCleaned > 0) {
        console.log(`Storage cleanup completed: ${(spaceCleaned / 1024).toFixed(2)}KB freed`);
        console.log(`Items remaining: ${Object.keys(localStorage).length}`);
      }
    } catch (error) {
      console.error('Storage cleanup failed:', error);
      throw new Error('Storage cleanup failed: ' + error.message);
    }
  }

  broadcastUpdate(data) {
    if (this.broadcastChannel) {
      this.broadcastChannel.postMessage({
        type: 'STORAGE_UPDATE',
        key: this.storageKey,
        data
      });
    }
  }

  subscribe(callback) {
    if (this.broadcastChannel) {
      this.broadcastChannel.onmessage = (event) => {
        if (event.data.type === 'STORAGE_UPDATE' && 
            event.data.key === this.storageKey) {
          callback(event.data.data);
        }
      };
    }

    // Also listen for storage events for older browsers
    window.addEventListener('storage', (event) => {
      if (event.key === this.storageKey) {
        try {
          const data = JSON.parse(event.newValue);
          callback(data);
        } catch (error) {
          console.error('Storage event handling failed:', error);
        }
      }
    });
  }
}

// shared-components/features/home/gridConstants.js

import { GRID, ASPECT_RATIOS } from '@shared/constants/designSystem';

/**
 * @typedef {Object} GridItemType
 * @property {string} id - Unique identifier
 * @property {string} title - Display title
 * @property {string} description - Item description
 * @property {string} image - Image URL
 * @property {string} link - Navigation link
 * @property {CELL_TYPES} type - Grid cell type
 */

export const CELL_TYPES = {
  TALL: 'tall',    // 4x5
  SHORT: 'short',  // 2x1
  SQUARE: 'square' // 1x1
};

/**
 * Maps cell types to their grid spans
 * @type {Object.<string, string>}
 */
export const CELL_SPANS = {
  [CELL_TYPES.TALL]: GRID.SPANS.TALL,
  [CELL_TYPES.SHORT]: GRID.SPANS.SHORT,
  [CELL_TYPES.SQUARE]: GRID.SPANS.SQUARE
};

/**
 * Maps cell types to aspect ratios
 * @type {Object.<string, string>}
 */
export const CELL_ASPECT_RATIOS = {
  [CELL_TYPES.TALL]: ASPECT_RATIOS.TALL,
  [CELL_TYPES.SHORT]: ASPECT_RATIOS.SHORT,
  [CELL_TYPES.SQUARE]: ASPECT_RATIOS.SQUARE
};
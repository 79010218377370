import { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const ThemeContext = createContext()

// Modified ThemeProvider with better error handling and SSR compatibility
export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('light'); // Start with default

  // Initialize theme after component mounts (client-side only)
  useEffect(() => {
    try {
      // Check localStorage first
      const stored = localStorage.getItem('theme')
      if (stored) {
        setTheme(stored);
        return;
      }
      
      // Then check system preference
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme('dark');
      }
    } catch (error) {
      console.warn('Failed to get theme preference:', error)
    }
  }, []);

  useEffect(() => {
    try {
      const root = window.document.documentElement
      // Start transition
      root.classList.add('transitioning-theme')
      // Remove old theme and add new one
      root.classList.remove('light', 'dark')
      root.classList.add(theme)
      
      // Safer DOM manipulation
      try {
        // Force a repaint to ensure theme changes are applied
        const displayValue = document.body.style.display;
        document.body.style.display = 'none'
        document.body.offsetHeight // Force reflow
        document.body.style.display = displayValue || '';
      } catch (err) {
        console.warn('Error during theme transition:', err);
      }
      
      try {
        localStorage.setItem('theme', theme)
      } catch (err) {
        console.warn('Could not save theme to localStorage:', err);
      }
      
      // Remove transition class after animation completes
      const timer = setTimeout(() => {
        root.classList.remove('transitioning-theme')
      }, 200) // Match duration with Tailwind config
      
      return () => clearTimeout(timer)
    } catch (error) {
      console.warn('Error applying theme:', error);
    }
  }, [theme]);

  // Listen for system theme changes
  useEffect(() => {
    try {
      if (!window.matchMedia) return;
      
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
      const handleChange = (e) => {
        const systemTheme = e.matches ? 'dark' : 'light'
        // Only update if user hasn't set a preference
        try {
          if (!localStorage.getItem('theme')) {
            setTheme(systemTheme)
          }
        } catch (err) {
          setTheme(systemTheme);
        }
      }

      mediaQuery.addEventListener('change', handleChange)
      return () => mediaQuery.removeEventListener('change', handleChange)
    } catch (error) {
      console.warn('Error setting up theme listener:', error);
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useTheme() {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}

// shared-components/services/AdminTourService.js

import { supabase } from './SupabaseService';

class AdminTourService {
  constructor() {
    this.tableName = 'performances';
  }

  async getAllTours() {
    const { data, error } = await supabase
      .from(this.tableName)
      .select('*')
      .order('date', { ascending: true });

    if (error) throw error;
    return data;
  }

  async getTourById(id) {
    const { data, error } = await supabase
      .from(this.tableName)
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    return data;
  }

  async createTour(tourData) {
    const formattedData = {
      date: tourData.date,
      time: tourData.time,
      venue: tourData.venue,
      address: tourData.address,
      city: tourData.city,
      country: tourData.country,
      status: tourData.status,
      post_code: tourData.postCode,
      booking_link: tourData.bookingLink,
      price_notes: tourData.priceNotes,
      venue_notes: tourData.venueNotes,
      additional_info: tourData.additionalInfo,
      event_type: tourData.eventType
    };
  
    const { data, error } = await supabase
      .from(this.tableName)
      .insert([formattedData])
      .select()
      .single();
  
    if (error) throw error;
    return data;
  }

  async updateTour(id, tourData) {
    const formattedData = {
      date: tourData.date,
      time: tourData.time,
      venue: tourData.venue,
      address: tourData.address,
      city: tourData.city,
      country: tourData.country,
      status: tourData.status,
      post_code: tourData.postCode,
      booking_link: tourData.bookingLink,
      price_notes: tourData.priceNotes,
      venue_notes: tourData.venueNotes,
      additional_info: tourData.additionalInfo,
      event_type: tourData.eventType
    };

    const { data, error } = await supabase
      .from(this.tableName)
      .update(formattedData)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  async deleteTour(id) {
    const { error } = await supabase
      .from(this.tableName)
      .delete()
      .eq('id', id);

    if (error) throw error;
    return true;
  }

  async updateTourStatus(id, status) {
    const { data, error } = await supabase
      .from(this.tableName)
      .update({ status })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  async updatePastEvents() {
    try {
      const today = new Date().toISOString().split('T')[0];
      
      // Find all past events still marked as upcoming
      const { data, error: fetchError } = await supabase
        .from(this.tableName)
        .select('id, date, venue')
        .lt('date', today)  // less than today's date
        .eq('status', 'upcoming');
      
      if (fetchError) throw fetchError;
      
      // If we found any events that need updating
      if (data && data.length > 0) {
        console.log(`Found ${data.length} past events to update:`, data);
        
        // Update all of them to past status
        const { error: updateError } = await supabase
          .from(this.tableName)
          .update({ status: 'past' })
          .in('id', data.map(item => item.id));
        
        if (updateError) throw updateError;
        
        console.log('Successfully updated past events to past status');
        return { success: true, updatedCount: data.length, updatedEvents: data };
      }
      
      return { success: true, updatedCount: 0, updatedEvents: [] };
    } catch (error) {
      console.error('Error updating past events:', error);
      throw error;
    }
  }
}

export const adminTourService = new AdminTourService();

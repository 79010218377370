import BasePage from '@shared/layout/BasePage'
// Import these when implementing the contact form later
// import ContactForm from '@shared/forms/ContactForm'
// import { TYPOGRAPHY, THEME } from '@shared/constants/designSystem'

function ContactPage() {
  return (
    <BasePage title="Contact">
      <div className="mx-auto max-w-4xl px-4">
        <div className="rounded-lg border border-gray-200 bg-white p-6 text-center dark:border-gray-700 dark:bg-gray-800">
          <h2 className="mb-4 text-xl font-semibold text-gray-600 dark:text-gray-300">Get in Touch</h2>
          
          <div className="mb-8">
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              For bookings, press inquiries, or general questions, please contact Jean directly:
            </p>
            <a 
              href="mailto:jean@jeanmartyn.com" 
              className="inline-block break-all px-6 py-3 text-lg font-medium text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-200"
            >
              jean@jeanmartyn.com
            </a>
          </div>

          <div>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              For website support or technical issues, please contact:
            </p>
            <a 
              href="mailto:support@jeanmartyn.com" 
              className="inline-block break-all px-6 py-3 text-lg font-medium text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-200"
            >
              support@jeanmartyn.com
            </a>
          </div>

          <p className="mt-6 text-sm text-gray-500 dark:text-gray-400">
            We aim to respond to all inquiries within 2-3 business days.
          </p>
        </div>
      </div>
    </BasePage>
  )
}

export default ContactPage
// src/components/shop/payment/stripe/StripeProvider.jsx
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

// Initialize Stripe once at the module level
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)

export function StripeProvider({ 
  children,
  amount, // amount in GBP (pounds)
  currency = 'gbp',
  mode = 'payment' // Allow for different modes if needed
}) {
  // Memoize the options to prevent unnecessary re-renders
  const options = useMemo(() => ({
    mode,
    currency,
    amount: amount ? Math.round(amount * 100) : undefined, // Convert to pence
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#0066cc',
        borderRadius: '4px',
      },
    },
    loader: 'auto',
    locale: 'en'
  }), [amount, currency, mode])

  // If no amount is provided, just use basic configuration
  const elementsOptions = amount ? options : { locale: 'en' }

  return (
    <Elements 
      stripe={stripePromise} 
      options={elementsOptions}
    >
      {children}
    </Elements>
  )
}

StripeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  amount: PropTypes.number,
  currency: PropTypes.oneOf(['gbp', 'eur']),
  mode: PropTypes.oneOf(['payment', 'setup', 'subscription'])
}

export default StripeProvider
// websites/jean-martyn/src/pages/HomePage.jsx

import GridLayout from '@shared/features/home/GridLayout'
import { jeanMartynGridItems } from '../config/gridItems';

function HomePage() {
  return (
    <GridLayout items={jeanMartynGridItems} />
  )
}

export default HomePage
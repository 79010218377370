import PropTypes from 'prop-types'
import { THEME } from '../../../constants/designSystem'

function ProductSkeleton({ viewMode = 'grid', delay = 0 }) {
  const isListMode = viewMode === 'list'
  
  return (
    <div 
      className={`
        animate-pulse ${THEME.COLORS.BG.DEFAULT} rounded-lg shadow-md
        ${isListMode ? 'flex gap-4' : ''}
        transition-opacity duration-300 ease-in-out
        opacity-0 animate-fadeIn
      `}
      style={{ animationDelay: `${delay}ms` }}
      role="status"
      aria-label="Loading product"
    >
      <div className={`
        ${isListMode ? 'w-1/3' : 'aspect-square'} 
        ${THEME.COLORS.BG.MUTED} rounded-t-lg
      `} />
      
      <div className="flex flex-col gap-4 p-4 w-full">
        <div className={`h-4 ${THEME.COLORS.BG.MUTED} rounded w-3/4`} />
        <div className={`h-4 ${THEME.COLORS.BG.MUTED} rounded w-1/2`} />
        <div className="flex justify-between items-center mt-auto">
          <div className={`h-6 ${THEME.COLORS.BG.MUTED} rounded w-20`} />
          <div className={`h-8 ${THEME.COLORS.BG.MUTED} rounded w-24`} />
        </div>
      </div>
    </div>
  )
}

ProductSkeleton.propTypes = {
  viewMode: PropTypes.oneOf(['grid', 'list']),
  delay: PropTypes.number
}

export default ProductSkeleton

// ResponsiveImage.jsx
import PropTypes from 'prop-types'
import { useState } from 'react'
import { THEME } from '@shared/constants/designSystem'

function ResponsiveImage({
  src,
  alt,
  className = "",
  loading = "lazy"
}) {
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleRetry = () => {
    setError(false)
    setIsLoading(true)
    const img = new Image()
    img.src = src
  }

  return (
    <div className="relative w-full h-full">
      <img
        src={src}
        alt={alt}
        loading={loading}
        onError={() => {
          console.warn(`Failed to load image: ${src}`)
          setError(true)
          setIsLoading(false)
        }}
        onLoad={() => setIsLoading(false)}
        className={`w-full h-full object-cover
          ${isLoading ? 'opacity-0' : 'opacity-100'}
          transition-opacity duration-300
          ${className}`}
      />
      
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex flex-col items-center gap-2">
            <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-gray-300" />
            <span className={`text-sm ${THEME.COLORS.TEXT.MUTED}`}>Loading...</span>
          </div>
        </div>
      )}
      
      {error && (
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <p className="text-sm text-gray-900 mb-2">Failed to load image</p>
          <button 
            onClick={handleRetry}
            className={`px-3 py-1 text-sm rounded ${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY} ${THEME.COLORS.HOVER.BG.PRIMARY}`}
          >
            Retry
          </button>
        </div>
      )}
    </div>
  )
}

ResponsiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  loading: PropTypes.oneOf(['lazy', 'eager'])
}

export default ResponsiveImage
import { TYPOGRAPHY, THEME } from '@shared/constants/designSystem';
import OrdersTable from '@shared/features/admin/orders/OrdersTable';

const OrdersPage = () => {
  return (
    <div className="space-y-6">
      <div>
        <h2 className={`${TYPOGRAPHY.HEADING.H2} ${THEME.COLORS.TEXT.DEFAULT} mb-4`}>Orders</h2>
        <p className={THEME.COLORS.TEXT.MUTED}>
          View and manage customer orders
        </p>
      </div>

      <OrdersTable />
    </div>
  );
};

export default OrdersPage;

import { supabase } from './SupabaseService';

class AuthService {
  constructor() {
    this.supabase = supabase; // Use the shared instance
  }

  async login(email, password, rememberMe = false) {
    const { data, error } = await this.supabase.auth.signInWithPassword({
      email,
      password,
      options: {
        persistSession: rememberMe
      }
    });

    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  async logout() {
    const { error } = await this.supabase.auth.signOut();
    if (error) {
      throw new Error(error.message);
    }
  }

  async getCurrentSession() {
    const { data: { session }, error } = await this.supabase.auth.getSession();
    if (error) {
      throw new Error(error.message);
    }
    return session;
  }

  onAuthStateChange(callback) {
    return this.supabase.auth.onAuthStateChange(callback);
  }
}

export const authService = new AuthService();

import ContentLayout from '@shared/features/admin/content/ContentLayout';
import PerformanceMonitor from '@shared/features/admin/PerformanceMonitor';
import { THEME } from '@shared/constants/designSystem';

const AdminPerformancePage = () => {
  return (
    <ContentLayout title="Performance Monitoring">
      <div className={`${THEME.COLORS.BG.DEFAULT} rounded-lg shadow-sm`}>
        <PerformanceMonitor />
      </div>
    </ContentLayout>
  );
};

export default AdminPerformancePage;

import { cn } from "@shared/lib/utils"
import PropTypes from 'prop-types'

const Alert = ({ variant = "default", className, children, action }) => {
  const variants = {
    default: "bg-slate-100 dark:bg-slate-800 text-slate-900 dark:text-slate-100",
    destructive: "bg-red-100 dark:bg-red-900/20 text-red-900 dark:text-red-200 border-red-200 dark:border-red-800",
    warning: "bg-yellow-100 dark:bg-yellow-900/20 text-yellow-900 dark:text-yellow-200 border-yellow-200 dark:border-yellow-800"
  }

  return (
    <div
      className={cn(
        "relative w-full rounded-lg border p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-slate-950 dark:[&>svg]:text-slate-50",
        variants[variant],
        className
      )}
    >
      {children}
      {action && (
        <div className="mt-2">
          {action}
        </div>
      )}
    </div>
  )
}

Alert.propTypes = {
  variant: PropTypes.oneOf(['default', 'destructive', 'warning']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  action: PropTypes.node
}

const AlertTitle = ({ className, children }) => {
  return (
    <h5 className={cn("mb-1 font-medium leading-none tracking-tight", className)}>
      {children}
    </h5>
  )
}

AlertTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

const AlertDescription = ({ className, children }) => {
  return (
    <div className={cn("text-sm [&_p]:leading-relaxed", className)}>
      {children}
    </div>
  )
}

AlertDescription.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Alert;
export { Alert, AlertTitle, AlertDescription }
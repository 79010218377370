import BasePage from '@shared/layout/BasePage'

function PressPage() {
  return (
    <BasePage title="Press & Media">
      <div className="mx-auto max-w-4xl px-4">
        <div className="rounded-lg border border-gray-200 bg-white p-6 text-center dark:border-gray-700 dark:bg-gray-800">
          <h2 className="mb-4 text-xl font-semibold text-gray-600 dark:text-gray-300">Coming Soon</h2>
          <p className="text-gray-600 dark:text-gray-300">
            This section will feature news coverage, interviews, and media articles about Jean&apos;s performances and musical journey. Please check back soon for updates.
          </p>
          <p className="mt-4 text-gray-600 dark:text-gray-300">
            For press inquiries, please use the <a href="/contact" className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300">Contact page</a>.
          </p>
        </div>
      </div>
    </BasePage>
  )
}

export default PressPage
// shared-components/features/tour/TourItem.jsx

import PropTypes from 'prop-types'
import { format, parseISO } from 'date-fns'
import { TYPOGRAPHY, THEME } from '@shared/constants/designSystem'

function InfoBox({ children }) {
  return (
    <div className="bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-lg p-3">
      <div className="text-blue-800 dark:text-blue-200">
        {children}
      </div>
    </div>
  )
}

InfoBox.propTypes = {
  children: PropTypes.node.isRequired
}

function TourItem({ performance }) {
  const formattedDate = format(new Date(performance.date), 'MMMM d, yyyy')
  const formattedTime = performance.time ? format(parseISO(`2000-01-01T${performance.time}`), 'h:mm a') : null

  const getStatusColor = (status) => {
    switch (status) {
      case 'upcoming':
        return 'text-green-600 dark:text-green-400'
      case 'sold_out':
        return 'text-red-600 dark:text-red-400'
      case 'cancelled':
        return 'text-gray-600 dark:text-gray-400'
      case 'postponed':
        return 'text-amber-600 dark:text-amber-400'
      case 'past':
        return 'text-blue-600 dark:text-blue-400'  
      default:
        return 'text-gray-600 dark:text-gray-400'
    }
  }

  const getStatusText = (status) => {
    switch (status) {
      case 'upcoming':
        return 'Upcoming'
      case 'sold_out':
        return 'Sold Out'
      case 'cancelled':
        return 'Cancelled'
      case 'postponed':
        return 'Postponed'
      case 'past':
        return 'Past'  
      default:
        return 'Status Unknown'
    }
  }

  const getEventTypeText = (type) => {
    switch (type) {
      case 'ticketed':
        return 'Ticketed Event'
      case 'door_sales':
        return 'Tickets sold at door'
      case 'mixed':
        return 'Mixed'
      case 'free':
        return 'Free Entry'
      case 'private':
        return 'Private Event'
      default:
        return 'Event Type Unknown'
    }
  }
  
  return (
    <div className="bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 rounded-lg p-4 shadow-sm transition-colors duration-200">
      <div className="flex flex-col gap-4">
        {/* Header: Date, Time, and Status */}
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start">
          <div className="flex gap-2 items-baseline">
            <div className={`${TYPOGRAPHY.BODY.LARGE} ${THEME.COLORS.TEXT.DEFAULT}`}>
              {formattedDate}
            </div>
            {formattedTime && (
              <div className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.MUTED}`}>
                {formattedTime}
              </div>
            )}
          </div>
          <div className={`text-base sm:text-sm mt-2 sm:mt-0 ${getStatusColor(performance.status)}`}>
            {getStatusText(performance.status)}
          </div>
        </div>

        {/* Venue and Location */}
        <div className="space-y-1">
          <div className={`${TYPOGRAPHY.BODY.LARGE} ${THEME.COLORS.TEXT.DEFAULT}`}>
            {performance.venue}
          </div>
          {performance.address && (
            <div className={`${THEME.COLORS.TEXT.MUTED}`}>{performance.address}</div>
          )}
          <div className={`${THEME.COLORS.TEXT.MUTED}`}>
            {performance.city}
            {performance.postCode && `, ${performance.postCode}`}
            {performance.country && `, ${performance.country}`}
          </div>
        </div>
     
          {/* Event Details & Additional Information */}
          <div className="space-y-2">   
          {(performance.eventType || performance.priceNotes || performance.venueNotes || performance.additionalInfo) && (
            <InfoBox>
              <div className="space-y-1">
                {getEventTypeText(performance.eventType)}
                {performance.priceNotes && (
                  <div><span className="font-medium">Price:</span> {performance.priceNotes}</div>
                )}
                {performance.venueNotes && (
                  <div><span className="font-medium">Venue Notes:</span> {performance.venueNotes}</div>
                )}
                {performance.additionalInfo && (
                  <div><span className="font-medium">Additional Info:</span> {performance.additionalInfo}</div>
                )}
              </div>
            </InfoBox>
          )}
        </div>

        {/* Booking Button - Only show for events with booking links */}
        {performance.bookingLink && performance.status !== 'cancelled' && performance.eventType !== 'door_sales' && (
          <div>
            <a
              href={performance.status === 'sold_out' ? '#' : performance.bookingLink}
              target="_blank"
              rel="noopener noreferrer"
              className={`inline-block px-4 py-3 sm:py-2 rounded text-base sm:text-sm min-h-[44px] sm:min-h-0 ${
                performance.status === 'sold_out'
                  ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600'
              } text-white transition-colors`}
              onClick={e => {
                if (performance.status === 'sold_out') {
                  e.preventDefault()
                }
              }}
            >
              {performance.status === 'sold_out' ? 'Sold Out' : 'Book Tickets'}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

TourItem.propTypes = {
  performance: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    venue: PropTypes.string.isRequired,
    address: PropTypes.string,
    city: PropTypes.string.isRequired,
    postCode: PropTypes.string,
    country: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['draft', 'published', 'past', 'pending', 'upcoming', 'sold_out', 'cancelled', 'postponed']).isRequired,
    eventType: PropTypes.oneOf(['ticketed', 'door_sales', 'mixed', 'free', 'private']).isRequired,
    bookingLink: PropTypes.string,
    priceNotes: PropTypes.string,
    venueNotes: PropTypes.string,
    additionalInfo: PropTypes.string
  }).isRequired
}

export default TourItem

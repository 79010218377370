import PropTypes from 'prop-types'
import { useProducts } from '../../../hooks/useProducts'
import { getProductGridClasses } from '../../../utils/ShopUtils'
import ProductCard from './ProductCard'
import ProductSkeleton from './ProductSkeleton'
import { useEffect, useState, useRef, useCallback, memo, forwardRef } from 'react'
import { analytics } from '../../../services/AnalyticsService'

const ITEMS_PER_PAGE = 12
const PERFORMANCE_THRESHOLD = 1000 // Only track performance if load takes more than 1s

const ProductLoadingState = memo(forwardRef(function ProductLoadingState(props, ref) {
  return (
    <div 
      ref={ref}
      className="col-span-full flex justify-center p-4"
      role="status"
      aria-busy="true"
    >
      <ProductSkeleton />
    </div>
  )
}))

const ProductErrorState = memo(function ProductErrorState({ error, onRetry }) {
  return (
    <div 
      role="alert" 
      className="text-center p-6 rounded-lg border border-red-200 dark:border-red-800 bg-red-50 dark:bg-red-900/10"
    >
      <div className="mb-4">
        <svg className="w-12 h-12 text-red-500 mx-auto mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <h2 className="text-lg font-semibold text-red-700 dark:text-red-400">
          Unable to Load Products
        </h2>
      </div>
      <p className="text-red-600 dark:text-red-400 mb-4">
        {error.message}
      </p>
      {error.canRetry ? (
        <button
          onClick={onRetry}
          className="px-4 py-2 bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-400 rounded-lg hover:bg-red-200 dark:hover:bg-red-900/50 focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          Try Again
        </button>
      ) : (
        <div className="text-sm text-red-600/80 dark:text-red-400/80">
          Please refresh the page or try again later
        </div>
      )}
      <div className="mt-6 text-sm text-gray-600 dark:text-gray-400">
        In the meantime, you can:
        <ul className="mt-2 space-y-1">
          <li>• Check out our featured products</li>
          <li>• Browse our categories</li>
          <li>• View your cart or wishlist</li>
        </ul>
      </div>
    </div>
  )
})

const ProductEmptyState = memo(function ProductEmptyState() {
  return (
    <div role="status" className="text-center p-4">
      <p>No products found.</p>
    </div>
  )
})

function ProductGrid() {
  const [visibleProducts, setVisibleProducts] = useState([])
  const [page, setPage] = useState(1)
  const loadingRef = useRef(null)
  const observerRef = useRef(null)
  const { products, isLoading, error, viewMode, trackEvent, refetch } = useProducts()
  
  // Track performance only on initial load
  useEffect(() => {
    const startTime = performance.now()
    
    return () => {
      const duration = performance.now() - startTime
      if (duration > PERFORMANCE_THRESHOLD) {
        analytics.trackMetric('initial_grid_load_time', duration)
      }
    }
  }, [])

  const loadMoreProducts = useCallback(() => {
    const start = 0
    const end = page * ITEMS_PER_PAGE
    // Take a slice from start to current page to ensure no duplicates
    const updatedProducts = products.slice(start, end)
    
    setVisibleProducts(updatedProducts)
    setPage(prev => prev + 1)
  }, [page, products])

  // Initialize intersection observer
  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect()
    }

    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && visibleProducts.length < products.length) {
          requestIdleCallback(() => loadMoreProducts(), { timeout: 2000 })
        }
      },
      { threshold: 0.1 }
    )

    const currentLoadingRef = loadingRef.current
    if (currentLoadingRef) {
      observerRef.current.observe(currentLoadingRef)
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
        observerRef.current = null
      }
    }
  }, [loadingRef, visibleProducts.length, products.length, loadMoreProducts])

  // Handle initial load
  useEffect(() => {
    if (products.length > 0 && visibleProducts.length === 0) {
      requestIdleCallback(() => loadMoreProducts(), { timeout: 2000 })
    }
  }, [products, loadMoreProducts, visibleProducts.length])

  if (error) {
    return (
      <ProductErrorState 
        error={{
          message: error.message || 'Failed to load products',
          canRetry: true
        }} 
        onRetry={() => {
          if (trackEvent) {
            trackEvent('products_retry_load')
          }
          refetch()
        }} 
      />
    )
  }

  if (isLoading) {
    return (
      <div 
        className={getProductGridClasses(viewMode)} 
        role="status"
        aria-busy="true"
      >
        {[...Array(6)].map((_, i) => (
          <ProductSkeleton 
            key={`skeleton-${i}`} 
            viewMode={viewMode}
            delay={i * 150}
          />
        ))}
      </div>
    )
  }

  if (!products?.length) {
    return <ProductEmptyState />
  }

  return (
    <div 
      className={getProductGridClasses(viewMode)}
      role="grid"
      aria-label="Products grid"
      data-testid="product-grid-loaded"
      onKeyDown={(e) => {
        const cards = e.currentTarget.querySelectorAll('[role="gridcell"]')
        const currentIndex = Array.from(cards).findIndex(card => card.contains(document.activeElement))
        
        switch(e.key) {
          case 'ArrowRight':
            if (currentIndex < cards.length - 1) {
              requestAnimationFrame(() => {
                cards[currentIndex + 1].querySelector('button')?.focus()
              })
            }
            break
          case 'ArrowLeft':
            if (currentIndex > 0) {
              requestAnimationFrame(() => {
                cards[currentIndex - 1].querySelector('button')?.focus()
              })
            }
            break
          case 'ArrowUp':
            if (currentIndex >= 3) {
              requestAnimationFrame(() => {
                cards[currentIndex - 3]?.querySelector('button')?.focus()
              })
            }
            break
          case 'ArrowDown':
            if (currentIndex + 3 < cards.length) {
              requestAnimationFrame(() => {
                cards[currentIndex + 3]?.querySelector('button')?.focus()
              })
            }
            break
        }
      }}
    >
      {visibleProducts.map(product => (
        <ProductCard 
          key={`product-${product.id}`} // Make keys more unique
          product={product} 
        />
      ))}
      {visibleProducts.length < products.length && (
        <ProductLoadingState ref={loadingRef} />
      )}
    </div>
  )
}

ProductErrorState.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    canRetry: PropTypes.bool.isRequired
  }).isRequired,
  onRetry: PropTypes.func.isRequired
}

export default memo(ProductGrid)
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useCart } from '@shared/hooks/useCart';
import { useProducts } from '@shared/hooks/useProducts';
import { THEME } from '@shared/constants/designSystem';
import { formatPrice } from '@shared/utils/MoneyUtils';
import ResponsiveImage from '@shared/ui/ResponsiveImage';
import Button from '@shared/ui/Button';

function ProductCard({ product }) {
  const { viewMode } = useProducts();
  const { name, description, price, image, inStock } = product;
  const { trackEvent } = useProducts();
  const [quantity, setQuantity] = useState(1);
  const { addItem } = useCart();
  
  const handleQuantityChange = (newQuantity) => {
    setQuantity(Math.max(1, newQuantity));
    trackEvent('product_quantity_changed', { productId: product.id, quantity: newQuantity });
  };

  const handleAddToCart = useCallback(() => {
    addItem({
      productId: product.id,
      name: product.name,
      price: price,
      quantity: quantity,
      maxQuantity: product.maxQuantity,
      image: product.image
    });

    trackEvent('add_to_cart', { productId: product.id, quantity });
  }, [product, quantity, addItem, trackEvent, price]);

  const isGridView = viewMode === 'grid';

  return (
    <article 
      className={`bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden
        ${isGridView ? 'w-full min-w-[280px] flex-1' : 'flex gap-6 w-full'}`}
      role="gridcell"
      aria-label={`${name} product card`}
    >
      <div className={`${isGridView ? 'w-full' : 'w-48'} relative`}>
        <div className="pt-[100%]">
          <div className="absolute inset-0">
            <ResponsiveImage
              src={image}
              alt={name}
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col p-4 gap-4">
        <div className="space-y-2">
          <h3 className={`text-lg font-semibold ${THEME.COLORS.TEXT.DEFAULT}`}>{name}</h3>
          <p className={`text-sm ${THEME.COLORS.TEXT.MUTED} line-clamp-2`}>{description}</p>
        </div>


        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-2">
            <input
              type="number"
              min="1"
              value={quantity}
              onChange={(e) => handleQuantityChange(parseInt(e.target.value))}
              className="w-16 rounded-lg border border-gray-300 dark:border-gray-700 px-2 py-1"
              aria-label="Quantity"
            />
            <span className="text-lg font-bold whitespace-nowrap">
              {formatPrice(price * quantity)}
            </span>
          </div>
          <Button
            variant="primary"
            size="sm"
            disabled={!inStock}
            onClick={handleAddToCart}
            className="min-h-[48px] w-full sm:w-auto touch-manipulation"
          >
            {inStock ? 'Add to Cart' : 'Out of Stock'}
          </Button>
        </div>
      </div>
    </article>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    inStock: PropTypes.bool.isRequired,
    maxQuantity: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};

export default ProductCard;

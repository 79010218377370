import { createClient } from '@supabase/supabase-js';

let supabaseInstance = null;

export const getSupabaseClient = () => {
  if (!supabaseInstance) {
    console.log('Creating new Supabase client');
    const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
    const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
    supabaseInstance = createClient(supabaseUrl, supabaseAnonKey);
  }
  return supabaseInstance;
};

export const supabase = getSupabaseClient();

export const fetchTourPerformances = async (showPast = false) => {
  try {
    // Database connection check
    const { data: tableInfo, error: tableError } = await supabase
      .from('performances')
      .select('count');
    
    if (tableError) {
      throw new Error(`Table check failed: ${tableError.message}`);
    }

    // Build our query
    let query = supabase
      .from('performances')
      .select('*');
    
    // By default, don't show past events unless explicitly requested
    if (!showPast) {
      query = query.not('status', 'eq', 'past');
    }
    
    // Order by date
    query = query.order('date', { ascending: true });
    
    // Execute the query
    const { data, error } = await query;

    if (error) {
      console.error('Error details:', error);
      throw error;
    }

    if (!data) {
      console.warn('No tour performances data received');
      return [];
    }

    // Get current date and time
    const now = new Date();
    
    // Map the data and handle status for past events
    return data.map(performance => {
      // Create a datetime for the performance
      let performanceDateTime;
      
      if (performance.time) {
        // If time is provided, combine date and time
        performanceDateTime = new Date(`${performance.date}T${performance.time}`);
      } else {
        // If no time is provided, use end of day (23:59:59)
        performanceDateTime = new Date(`${performance.date}T23:59:59`);
      }
      
      // Check if this is a past event that still has an "upcoming" status
      const isPastEvent = performanceDateTime < now;
      const needsStatusUpdate = isPastEvent && performance.status === 'upcoming';
      
      // For display purposes only, show as "past"
      const displayStatus = needsStatusUpdate ? 'past' : performance.status;
      
      return {
        id: performance.id.toString(),
        date: performance.date,
        time: performance.time,
        venue: performance.venue,
        address: performance.address,
        city: performance.city,
        postCode: performance.post_code,
        country: performance.country,
        status: displayStatus,
        eventType: performance.event_type,
        bookingLink: performance.booking_link || '#',
        priceNotes: performance.price_notes || '',
        venueNotes: performance.venue_notes || '',
        additionalInfo: performance.additional_info || ''
      };
    });
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};

// New order management functions
export const createOrder = async ({
  orderReference,
  customerEmail,
  shippingAddress,
  shippingOption,
  totalAmount,
  vatAmount,
  currency,
  stripePaymentIntent
}) => {
  try {
    const { data: order, error } = await supabase
      .from('orders')
      .insert({
        order_reference: orderReference,
        customer_email: customerEmail,
        shipping_address: shippingAddress,
        shipping_option: shippingOption,
        total_amount: Math.round(totalAmount * 100), // Convert to pence
        vat_amount: Math.round(vatAmount * 100),     // Convert to pence
        currency,
        stripe_payment_intent: stripePaymentIntent,
        status: 'confirmed'  // Initial status
      })
      .select()
      .single();

    if (error) throw error;
    return order;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const createOrderItems = async (orderId, items) => {
  try {
    const orderItems = items.map(item => ({
      order_id: orderId,
      product_id: item.id,
      quantity: item.quantity,
      price_at_time: Math.round(item.price * 100) // Convert to pence
    }));

    const { data, error } = await supabase
      .from('order_items')
      .insert(orderItems)
      .select();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating order items:', error);
    throw error;
  }
};

export const getOrderByReference = async (orderReference, customerEmail) => {
  try {
    const { data: order, error } = await supabase
      .from('orders')
      .select(`
        *,
        order_items (
          *,
          product:products (
            name,
            description
          )
        )
      `)
      .eq('order_reference', orderReference)
      .eq('customer_email', customerEmail)
      .single();

    if (error) throw error;
    return order;
  } catch (error) {
    console.error('Error fetching order:', error);
    throw error;
  }
};

export const updateOrderStatus = async (orderId, status) => {
  try {
    const { data: order, error } = await supabase
      .from('orders')
      .update({ status, updated_at: new Date().toISOString() })
      .eq('id', orderId)
      .select()
      .single();

    if (error) throw error;
    return order;
  } catch (error) {
    console.error('Error updating order status:', error);
    throw error;
  }
};

// Helper function to handle failed orders
export const markOrderAsFailed = async (orderId, reason) => {
  try {
    const { data: order, error } = await supabase
      .from('orders')
      .update({
        status: 'failed',
        updated_at: new Date().toISOString(),
        additional_info: { failure_reason: reason }
      })
      .eq('id', orderId)
      .select()
      .single();

    if (error) throw error;
    return order;
  } catch (error) {
    console.error('Error marking order as failed:', error);
    throw error;
  }
};
import PropTypes from 'prop-types'
import Section from '@shared/content/Section'
import ContentBlock from '@shared/content/ContentBlock'
import { CONTENT_TYPES, SECTION_WIDTHS } from '@shared/constants/contentTypes'
import { THEME } from '@shared/constants/designSystem'
import { TYPOGRAPHY, SPACING } from '@shared/constants/designSystem'

const BasePage = ({ title, children }) => {
  return (
    <article className={SPACING.LARGE}>
      <Section width={SECTION_WIDTHS.NORMAL}>
        <header className={SPACING.DEFAULT}>
          <h1 className={`${TYPOGRAPHY.HEADING.H1} ${THEME.COLORS.TEXT.DEFAULT}`}>
            {title}
          </h1>
        </header>
        <ContentBlock type={CONTENT_TYPES.TEXT}>
          {children}
        </ContentBlock>
      </Section>
    </article>
  )
}

BasePage.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default BasePage

// src/pages/PrivacyPage.jsx
import BasePage from '@shared/layout/BasePage'

function PrivacyPage() {
  return (
    <BasePage title="Privacy Policy">
      <div className="mx-auto max-w-4xl px-4">
        <div className="prose prose-lg dark:prose-invert">
          <h2>Privacy Policy</h2>
          <p>Last updated: 6th February 2025</p>
          
          <h3>Overview</h3>
          <p>This website is designed to provide information about Jean Martyn and her performances. We are committed to protecting your privacy and aim to collect minimal personal information.</p>

          <h3>Information Collection</h3>
          <p>This website does not use contact forms or cookies, and does not actively collect personal information. The only data stored locally may include:</p>
          <ul>
            <li>Your preferred gallery view settings, if changed</li>
            <li>Standard server logs maintained by our hosting provider</li>
          </ul>

          <h3>External Links</h3>
          <p>Our website contains links to external websites, including:</p>
          <ul>
            <li>Event booking platforms</li>
            <li>Social media profiles</li>
            <li>Venue websites</li>
          </ul>
          <p>Please note that clicking these links will take you to external websites with their own privacy policies and data collection practices, which are beyond our control.</p>

          <h3>Contact Information</h3>
          <p>When you use the email link on our contact page, you will be directed to your own email client. Any information you choose to share via email is subject to your email provider&apos;s privacy policy.</p>

          <h3>Updates to This Policy</h3>
          <p>We may update this privacy policy as our website evolves. Any changes will be reflected here with an updated date.</p>

          <h3>Questions</h3>
          <p>If you have any questions about this privacy policy, please contact us through the details provided on our Contact page.</p>
        </div>
      </div>
    </BasePage>
  )
}

export default PrivacyPage
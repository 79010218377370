// shared-components/features/admin/metrics/ExportMetrics.jsx

import { useState } from 'react';
import { DashboardCard } from '@shared/features/admin/dashboard/DashboardCard';
import { THEME, TYPOGRAPHY } from '@shared/constants/designSystem';
import { exportService } from '@shared/services/ExportService';

const ExportMetrics = () => {
  const [format, setFormat] = useState('json');
  const [isExporting, setIsExporting] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleExport = async () => {
    setIsExporting(true);
    setProgress(0);
    
    try {
      await exportService.exportData(format, (progress) => {
        setProgress(progress);
      });
    } catch (error) {
      console.error('Export failed:', error);
    } finally {
      setIsExporting(false);
      setProgress(0);
    }
  };

  return (
    <DashboardCard title="Export Data">
      <div className="space-y-4">
        <div className="flex items-center gap-4">
          <select
            value={format}
            onChange={(e) => setFormat(e.target.value)}
            className={`
              px-3 py-2 rounded-md border
              ${THEME.COLORS.BG.MUTED}
              ${THEME.COLORS.TEXT.DEFAULT}
            `}
            disabled={isExporting}
          >
            <option value="json">JSON</option>
            <option value="csv">CSV</option>
          </select>
          
          <button
            onClick={handleExport}
            disabled={isExporting}
            className={`
              px-4 py-2 rounded-md
              ${THEME.COLORS.BG.PRIMARY}
              ${THEME.COLORS.TEXT.ON_PRIMARY}
              disabled:opacity-50
            `}
          >
            {isExporting ? 'Exporting...' : 'Export Data'}
          </button>
        </div>

        {isExporting && (
          <div className="space-y-2">
            <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
              <div
                className={`h-full ${THEME.COLORS.BG.PRIMARY} transition-all duration-300`}
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className={`${TYPOGRAPHY.BODY.SMALL} ${THEME.COLORS.TEXT.MUTED}`}>
              {progress}% complete
            </p>
          </div>
        )}
      </div>
    </DashboardCard>
  );
};

export default ExportMetrics;

// shared-components/features/tour/TourList.jsx

import { useEffect, useState, useCallback, useRef } from 'react';
import { fetchTourPerformances } from '@shared/services/SupabaseService';
import TourItem from './TourItem';
import TourItemSkeleton from './TourItemSkeleton';
import TourFilters from './TourFilters';
import { Alert } from '@shared/ui/alert';

function TourList() {
  const [performances, setPerformances] = useState([]);
  const [filteredPerformances, setFilteredPerformances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [error, setError] = useState(null);
  
  // Use ref to store initial filter application
  const initialFilterApplied = useRef(false);

  // Filter function - memoized to stay stable
  const handleFilterChange = useCallback(({ startDate, endDate, location, searchText, searchField }) => {
    let filtered = [...performances];

    if (startDate) {
      filtered = filtered.filter(perf => perf.date >= startDate);
    }
    if (endDate) {
      filtered = filtered.filter(perf => perf.date <= endDate);
    }
    if (location) {
      const searchTerm = location.toLowerCase();
      filtered = filtered.filter(perf => 
        perf.city.toLowerCase().includes(searchTerm) ||
        perf.country.toLowerCase().includes(searchTerm)
      );
    }
    if (searchText) {
      const term = searchText.toLowerCase();
      filtered = filtered.filter(perf => {
        const formattedDate = new Date(perf.date).toLocaleDateString('en-GB', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }).toLowerCase();

        const formattedTime = perf.time ? 
          new Date(`2000-01-01T${perf.time}`).toLocaleTimeString('en-GB', {
            hour: 'numeric',
            minute: 'numeric'
          }).toLowerCase() : '';

        if (searchField === 'all') {
          return (
            perf.venue.toLowerCase().includes(term) ||
            perf.city.toLowerCase().includes(term) ||
            perf.country.toLowerCase().includes(term) ||
            formattedDate.includes(term) ||
            formattedTime.includes(term) ||
            (perf.address || '').toLowerCase().includes(term) ||
            (perf.postCode || '').toLowerCase().includes(term) ||
            (perf.priceNotes || '').toLowerCase().includes(term) ||
            (perf.venueNotes || '').toLowerCase().includes(term) ||
            (perf.additionalInfo || '').toLowerCase().includes(term) ||
            perf.status.toLowerCase().includes(term) ||
            perf.eventType.toLowerCase().replace('_', ' ').includes(term)
          );
        }
        
        switch(searchField) {
          case 'venue':
            return perf.venue.toLowerCase().includes(term);
          case 'city':
            return perf.city.toLowerCase().includes(term);
          case 'country':
            return perf.country.toLowerCase().includes(term);
          case 'date':
            return formattedDate.includes(term);
          case 'time':
            return formattedTime.includes(term);
          default:
            return perf[searchField]?.toLowerCase().includes(term) || false;
        }
      });
    }

    setFilteredPerformances(filtered);
    setCurrentPage(1);
  }, [performances]);

  // Reset handler
  const handleFilterReset = useCallback(() => {
    setFilteredPerformances(performances);
    setCurrentPage(1);
  }, [performances]);

  // Retry handler
  const handleRetry = async () => {
    setError(null);
    setLoading(true);
    try {
      const data = await fetchTourPerformances();
      setPerformances(data);
      setFilteredPerformances(data);
      setCurrentPage(1);
    } catch (err) {
      setError(err.message || 'Failed to load performances.');
    } finally {
      setLoading(false);
    }
  };

  // Effect for initial data load
  useEffect(() => {
    const loadPerformances = async () => {
      try {
        const data = await fetchTourPerformances();
        setPerformances(data);
        setFilteredPerformances(data); // Set initial filtered data
        
        // Mark that we haven't applied initial filters yet
        initialFilterApplied.current = false;
      } catch (error) {
        console.error('Error details:', error);
        setError(error.message || 'Failed to load performances.');
      } finally {
        setLoading(false);
      }
    };
  
    loadPerformances();
  }, []); // Empty dependency array - only runs once

  // Separate effect for applying saved filters after data is loaded
  useEffect(() => {
    if (performances.length > 0 && !initialFilterApplied.current) {
      const savedFilters = JSON.parse(localStorage.getItem('tourFilterState') || '{}');
      if (Object.keys(savedFilters).length > 0) {
        handleFilterChange(savedFilters);
      }
      initialFilterApplied.current = true;
    }
  }, [performances, handleFilterChange]);

  if (loading) {
    return (
      <div className="space-y-4 sm:space-y-2 animate-fadeIn">
        {[...Array(3)].map((_, index) => (
          <TourItemSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <Alert 
        variant="destructive"
        className="my-8"
        action={
          <button
            onClick={handleRetry}
            className="px-4 py-2 bg-red-600 hover:bg-red-700 dark:bg-red-800 dark:hover:bg-red-700 text-white rounded transition-colors"
          >
            Retry Loading
          </button>
        }
      >
        <h3 className="font-medium mb-1">Error Loading Performances</h3>
        <p className="text-sm">{error}</p>
        <p className="text-sm mt-1">Please try again or contact support if the problem persists.</p>
      </Alert>
    );
  }

  if (!performances || performances.length === 0) {
    return (
      <Alert 
        variant="warning"
        className="my-8"
      >
        <h3 className="font-medium">No Performances Found</h3>
        <p className="text-sm mt-1">There are no upcoming performances scheduled at this time. Please check back later.</p>
      </Alert>
    );
  }

  const totalPages = Math.ceil(filteredPerformances.length / pageSize);
  const paginatedPerformances = filteredPerformances.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const sortedPerformances = [...paginatedPerformances].sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  return (
    <>
      <TourFilters 
        onFilterChange={handleFilterChange}
        onReset={handleFilterReset}
        className="mb-6"
      />
      <div className="space-y-4 sm:space-y-2 animate-fadeIn">
        {sortedPerformances.map(performance => (
          <TourItem key={performance.id} performance={performance} />
        ))}
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center gap-2">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-3 py-2 bg-slate-800 dark:bg-slate-700 hover:bg-slate-700 dark:hover:bg-slate-600 text-white rounded disabled:opacity-50 disabled:hover:bg-slate-800 dark:disabled:hover:bg-slate-700 transition-colors"
          >
            Previous
          </button>
          
          <div className="flex gap-1">
            {[...Array(totalPages)].map((_, idx) => (
              <button
                key={idx + 1}
                onClick={() => setCurrentPage(idx + 1)}
                disabled={currentPage === idx + 1}
                className={`w-8 h-8 rounded flex items-center justify-center transition-colors ${
                  currentPage === idx + 1
                    ? 'bg-blue-600 text-white'
                    : 'bg-slate-700 hover:bg-slate-600 text-white'
                }`}
              >
                {idx + 1}
              </button>
            ))}
          </div>
          
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-3 py-2 bg-slate-700 hover:bg-slate-600 text-white rounded disabled:opacity-50 disabled:hover:bg-slate-700 transition-colors"
          >
            Next
          </button>
        </div>
        <div className="flex items-center gap-2">
          <label htmlFor="pageSize" className="text-slate-300">Show:</label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setCurrentPage(1);
            }}
            className="px-3 pr-8 py-2 bg-white dark:bg-slate-800 text-slate-900 dark:text-white rounded border border-slate-300 dark:border-slate-700 focus:outline-none focus:border-blue-500 transition-theme"
          >
            {[5, 10, 20].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

export default TourList;
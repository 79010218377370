import { analytics } from './AnalyticsService';

export class ExportService {
  constructor() {
    this.supportedFormats = ['json', 'csv'];
  }

  async exportData(format = 'json', onProgress) {
    if (!this.supportedFormats.includes(format)) {
      throw new Error(`Unsupported format: ${format}`);
    }

    // Get all data from analytics service
    const data = {
      metrics: Object.fromEntries(analytics.metrics),
      events: analytics.events,
      errors: analytics.errors
    };

    onProgress?.(0);

    // Format the data
    const formattedData = format === 'json' 
      ? await this.formatJson(data, onProgress)
      : await this.formatCsv(data, onProgress);

    onProgress?.(90);

    // Create and download the file
    const blob = new Blob([formattedData], {
      type: format === 'json' ? 'application/json' : 'text/csv'
    });
    
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `analytics-export.${format}`;
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    onProgress?.(100);
  }

  async formatJson(data, onProgress) {
    onProgress?.(50);
    return JSON.stringify(data, null, 2);
  }

  async formatCsv(data, onProgress) {
    const rows = [];
    
    // Headers
    rows.push('timestamp,metric,value');
    
    // Metrics
    onProgress?.(30);
    Object.entries(data.metrics).forEach(([metric, values]) => {
      values.forEach(({ timestamp, value }) => {
        rows.push(`${timestamp},"${metric}",${value}`);
      });
    });
    
    // Events
    onProgress?.(60);
    data.events.forEach(event => {
      rows.push(`${event.timestamp},"event_${event.category}","${event.action}"`);
    });
    
    // Errors
    onProgress?.(80);
    data.errors.forEach(error => {
      rows.push(`${error.timestamp},"error","${error.message.replace(/"/g, '""')}"`);
    });
    
    return rows.join('\n');
  }
}

export const exportService = new ExportService();

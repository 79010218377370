// shared-components/features/admin/content/shop/AdminProductForm.jsx

import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { THEME } from '../../../../constants/designSystem';
import { adminProductService } from '../../../../services/AdminProductService';

const AdminProductForm = ({ product, onSubmit, onCancel }) => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: 0,
    image: '',
    in_stock: true,
    stock_quantity: 0,
    max_quantity: null,
    category_id: '',
    status: 'draft'
  });

  const loadCategories = useCallback(async () => {
    try {
      const data = await adminProductService.getCategories();
      setCategories(data);
    } catch (err) {
      console.error('Error loading categories:', err);
      setError('Failed to load product categories');
    }
  }, []);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  // Log categories when they change
  useEffect(() => {
    console.log('Categories loaded:', categories);
  }, [categories]);

  useEffect(() => {
    if (product) {
      setFormData({
        name: product.name,
        description: product.description,
        price: product.price,
        image: product.image || '',
        in_stock: product.in_stock ?? true,
        stock_quantity: product.stock_quantity ?? 0,
        max_quantity: product.max_quantity || null,
        category_id: product.category_id || '',
        status: product.status || 'draft'
      });
    }
  }, [product]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'number' ? Number(value) : 
              type === 'checkbox' ? e.target.checked : 
              value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await onSubmit(formData);
    } catch (err) {
      setError(err.message || 'Failed to save product. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Basic Details */}
        <div className="md:col-span-2">
          <label className="block text-sm font-medium mb-1">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="md:col-span-2">
          <label className="block text-sm font-medium mb-1">Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            rows="3"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        {/* Price and Stock */}
        <div>
          <label className="block text-sm font-medium mb-1">Price</label>
          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            required
            min="0"
            step="0.01"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Stock Quantity</label>
          <input
            type="number"
            name="stockQuantity"
            value={formData.stockQuantity}
            onChange={handleChange}
            required
            min="0"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Max Purchase Quantity</label>
          <input
            type="number"
            name="maxQuantity"
            value={formData.maxQuantity || ''}
            onChange={handleChange}
            min="0"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Status</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          >
            <option value="draft">Draft</option>
            <option value="published">Published</option>
            <option value="archived">Archived</option>
            <option value="out_of_stock">Out of Stock</option>
          </select>
        </div>

        {/* Category */}
        <div className="md:col-span-2">
          <label className="block text-sm font-medium mb-1">Category</label>
          <select
            name="categoryId"
            value={formData.categoryId}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          >
            <option value="">Select Category</option>
            {Array.isArray(categories) && categories.map(category => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        {/* Image */}
        <div className="md:col-span-2">
          <label className="block text-sm font-medium mb-1">Image URL</label>
          <input
            type="url"
            name="image"
            value={formData.image}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        {/* Stock Status */}
        <div className="md:col-span-2">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="inStock"
              checked={formData.inStock}
              onChange={handleChange}
              className="rounded border-gray-300"
            />
            <span className="text-sm font-medium">In Stock</span>
          </label>
        </div>
      </div>

      {error && (
        <div className="p-4 mb-4 text-red-700 bg-red-100 dark:bg-red-900 dark:text-red-200 rounded-md">
          <p className="font-medium">Error Saving Product</p>
          <p className="text-sm mt-1">{error}</p>
        </div>
      )}

      <div className="flex justify-end gap-4">
        <button
          type="button"
          onClick={onCancel}
          className={`${THEME.COLORS.BG.SECONDARY} ${THEME.COLORS.TEXT.ON_SECONDARY} px-4 py-2 rounded-md`}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY} px-4 py-2 rounded-md`}
        >
          {product ? 'Update Product' : 'Create Product'}
        </button>
      </div>
    </form>
  );
};

AdminProductForm.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    image: PropTypes.string,
    in_stock: PropTypes.bool,
    stock_quantity: PropTypes.number,
    max_quantity: PropTypes.number,
    category_id: PropTypes.string,
    product_categories: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    status: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default AdminProductForm;

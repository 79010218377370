// shared-components/features/admin/orders/OrderDetails.jsx

import PropTypes from 'prop-types';
import { THEME, TYPOGRAPHY } from '../../../constants/designSystem';
import { formatPrice } from '../../../utils/MoneyUtils';
import { formatDeliveryDate } from '../../../utils/ShippingUtils';

const OrderDetails = ({ order, onClose }) => {
  if (!order) return null;

  const renderAddress = (address) => (
    <div className="space-y-1">
      <p>{address.name}</p>
      <p>{address.street}</p>
      <p>{address.city}</p>
      <p>{address.postalCode}</p>
      <p>{address.country}</p>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center p-4 z-50">
      <div className={`bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto`}>
        <div className="p-6 space-y-6">
          {/* Header */}
          <div className="flex justify-between items-start">
            <div>
              <h2 className={`${TYPOGRAPHY.HEADING.H2} ${THEME.COLORS.TEXT.DEFAULT}`}>
                Order {order.order_reference}
              </h2>
              <p className={`${THEME.COLORS.TEXT.MUTED}`}>
                Placed on {formatDeliveryDate(new Date(order.created_at))}
              </p>
            </div>
            <button
              onClick={onClose}
              className={`${THEME.COLORS.BG.SECONDARY} ${THEME.COLORS.TEXT.ON_SECONDARY} p-2 rounded-full`}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Status */}
          <div>
            <span className={`px-3 py-1 rounded-full text-sm
              ${order.status === 'paid' ? 'bg-green-100 text-green-800' : 
                order.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
                'bg-gray-100 text-gray-800'}`}>
              {order.status}
            </span>
          </div>

          {/* Customer & Shipping */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 className={`${TYPOGRAPHY.HEADING.H3} ${THEME.COLORS.TEXT.DEFAULT} mb-3`}>
                Customer Information
              </h3>
              <p className="mb-2">{order.customer_email}</p>
              <h4 className={`${TYPOGRAPHY.HEADING.H4} ${THEME.COLORS.TEXT.DEFAULT} mt-4 mb-2`}>
                Shipping Address
              </h4>
              {renderAddress(order.shipping_address)}
            </div>
            <div>
              <h3 className={`${TYPOGRAPHY.HEADING.H3} ${THEME.COLORS.TEXT.DEFAULT} mb-3`}>
                Shipping Method
              </h3>
              <p>{order.shipping_option.name}</p>
              <p className={THEME.COLORS.TEXT.MUTED}>
                {order.shipping_option.description}
              </p>
            </div>
          </div>

          {/* Order Items */}
          <div>
            <h3 className={`${TYPOGRAPHY.HEADING.H3} ${THEME.COLORS.TEXT.DEFAULT} mb-3`}>
              Order Items
            </h3>
            <div className="border rounded-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className={THEME.COLORS.BG.CONTENT.HEADER}>
                  <tr>
                    <th className="px-4 py-3 text-left">Product</th>
                    <th className="px-4 py-3 text-left">Quantity</th>
                    <th className="px-4 py-3 text-right">Price</th>
                    <th className="px-4 py-3 text-right">Total</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {order.order_items.map((item) => (
                    <tr key={item.id}>
                      <td className="px-4 py-3">{item.products.name}</td>
                      <td className="px-4 py-3">{item.quantity}</td>
                      <td className="px-4 py-3 text-right">
                        {formatPrice(item.price_at_time / 100)}
                      </td>
                      <td className="px-4 py-3 text-right">
                        {formatPrice((item.price_at_time * item.quantity) / 100)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Order Totals */}
          <div className="border-t pt-4">
            <div className="space-y-2">
              <div className="flex justify-between">
                <span>Subtotal</span>
                <span>{formatPrice((order.total_amount - order.vat_amount) / 100)}</span>
              </div>
              <div className="flex justify-between">
                <span>VAT</span>
                <span>{formatPrice(order.vat_amount / 100)}</span>
              </div>
              <div className="flex justify-between font-bold">
                <span>Total</span>
                <span>{formatPrice(order.total_amount / 100)}</span>
              </div>
            </div>
          </div>

          {/* Payment Information */}
          <div>
            <h3 className={`${TYPOGRAPHY.HEADING.H3} ${THEME.COLORS.TEXT.DEFAULT} mb-3`}>
              Payment Information
            </h3>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span>Payment Method</span>
                <span>Stripe</span>
              </div>
              <div className="flex justify-between">
                <span>Payment ID</span>
                <span className="font-mono text-sm">
                  {order.stripe_payment_intent}
                </span>
              </div>
              <div className="flex justify-between">
                <span>Payment Status</span>
                <span className="capitalize">{order.status}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    order_reference: PropTypes.string.isRequired,
    customer_email: PropTypes.string.isRequired,
    shipping_address: PropTypes.object.isRequired,
    shipping_option: PropTypes.object.isRequired,
    total_amount: PropTypes.number.isRequired,
    vat_amount: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    stripe_payment_intent: PropTypes.string.isRequired,
    order_items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        price_at_time: PropTypes.number.isRequired,
        products: PropTypes.shape({
          name: PropTypes.string.isRequired
        }).isRequired
      })
    ).isRequired
  }),
  onClose: PropTypes.func.isRequired
};

export default OrderDetails;

// shared-components/features/admin/content/tours/AdminTourDateList.jsx

import { useState, useEffect } from 'react';
import { adminTourService } from '../../../../services/AdminTourService';
import ContentCrudBase from '../ContentCrudBase';
import AdminTourDateForm from './AdminTourDateForm';
import TourItem from '../../../tour/TourItem';
import { THEME } from '../../../../constants/designSystem';

const AdminTourDateList = () => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTour, setSelectedTour] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    loadTours();
  }, []);

  const loadTours = async () => {
    setError(null);
    setLoading(true);
    try {
      // First update any past events
      await adminTourService.updatePastEvents();
      
      // Then load all tours
      const data = await adminTourService.getAllTours();
      
      // Sort by date (upcoming first, then past dates)
      const sortedData = [...data].sort((a, b) => {
        // Your sorting logic here
      });
      
      setTours(sortedData);
    } catch (error) {
      console.error('Error loading tours:', error);
      setError('Failed to load tour dates. Please refresh the page to try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setSelectedTour(null);
    setIsFormOpen(true);
  };

  const handleEdit = (tour) => {
    setSelectedTour(tour);
    setIsFormOpen(true);
  };

  const handleDelete = async (tour) => {
    if (window.confirm('Are you sure you want to delete this tour date?')) {
      try {
        await adminTourService.deleteTour(tour.id);
        await loadTours();
      } catch (error) {
        console.error('Error deleting tour:', error);
      }
    }
  };

  const handleSubmit = async (formData) => {
    try {
      if (selectedTour) {
        await adminTourService.updateTour(selectedTour.id, formData);
      } else {
        await adminTourService.createTour(formData);
      }
      await loadTours();
      setIsFormOpen(false);
      setSelectedTour(null);
    } catch (error) {
      console.error('Error saving tour:', error);
      throw new Error(error.message || 'Failed to save tour date. Please check your input and try again.');
    }
  };

  const renderTourItem = (tour) => (
    <div className="flex-1">
      <TourItem performance={tour} />
    </div>
  );

  if (isFormOpen) {
    return (
      <div className={`${THEME.COLORS.BG.CONTENT.ITEM} p-6 rounded-lg`}>
        <AdminTourDateForm
          tour={selectedTour}
          onSubmit={handleSubmit}
          onCancel={() => setIsFormOpen(false)}
        />
      </div>
    );
  }

  return (
    <ContentCrudBase
      title="Tour Dates"
      items={tours}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDelete={handleDelete}
      renderItem={renderTourItem}
      loading={loading}
    />
  );
};

export default AdminTourDateList;

// src/pages/PaymentConfirmationPage.jsx
import { useEffect, useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { useNavigate } from 'react-router-dom'
import BasePage from '@shared/layout/BasePage'
import { THEME, TYPOGRAPHY } from '@shared/constants/designSystem'
import Button from '@shared/ui/Button'
import StripeProvider from '@shared/features/shop/payment/stripe/StripeProvider'
import PropTypes from 'prop-types'
import { useCart } from '@shared/hooks/useCart'
import { CartProvider } from '@shared/context/CartContext'
function PaymentStatus({ status, message }) {
  if (status === 'processing') {
    return (
      <div className="animate-pulse space-y-4">
        <div className={`${THEME.COLORS.BG.DEFAULT} p-6 rounded-lg text-center`}>
          <h2 className={`${TYPOGRAPHY.HEADING.H2} mb-4`}>Processing Payment</h2>
          <p className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.MUTED}`}>
            Please wait while we confirm your payment...
          </p>
        </div>
      </div>
    )
  }

  if (status === 'success') {
    return (
      <div className="space-y-6">
        <div className={`${THEME.COLORS.BG.DEFAULT} p-6 rounded-lg text-center`}>
          <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-green-100 dark:bg-green-900/20 flex items-center justify-center">
            <svg 
              className="w-8 h-8 text-green-600 dark:text-green-400" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M5 13l4 4L19 7" 
              />
            </svg>
          </div>
          <h2 className={`${TYPOGRAPHY.HEADING.H2} mb-4`}>Payment Successful</h2>
          <p className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.MUTED} mb-6`}>
            Thank you for your purchase! You will receive a confirmation email shortly.
          </p>
          <div className="space-x-4">
            <Button 
              variant="primary"
              onClick={() => window.location.href = '/shop'}
            >
              Continue Shopping
            </Button>
          </div>
        </div>
      </div>
    )
  }

  if (status === 'error') {
    return (
      <div className="space-y-6">
        <div className={`${THEME.COLORS.BG.DEFAULT} p-6 rounded-lg text-center`}>
          <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-red-100 dark:bg-red-900/20 flex items-center justify-center">
            <svg 
              className="w-8 h-8 text-red-600 dark:text-red-400" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M6 18L18 6M6 6l12 12" 
              />
            </svg>
          </div>
          <h2 className={`${TYPOGRAPHY.HEADING.H2} mb-4`}>Payment Failed</h2>
          <p className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.MUTED} mb-2`}>
            {message || 'Sorry, there was a problem processing your payment.'}
          </p>
          <p className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.MUTED} mb-6`}>
            Please try again or contact support if the problem persists.
          </p>
          <div className="space-x-4">
            <Button 
              variant="primary"
              onClick={() => window.location.href = '/shop'}
            >
              Return to Shop
            </Button>
            <Button 
              variant="outline"
              onClick={() => window.location.href = '/contact'}
            >
              Contact Support
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return null
}

function PaymentConfirmationContent() {
  const stripe = useStripe()
  const navigate = useNavigate()
  const { clearCart } = useCart() // Add this line
  const [status, setStatus] = useState('processing')
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (!clientSecret) {
      navigate('/shop')
      return
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case 'succeeded':
            // Clear cart before setting success status
            clearCart()
            setStatus('success')
            break
          case 'processing':
            setStatus('processing')
            break
          case 'requires_payment_method':
            setStatus('error')
            setMessage('Your payment was not successful, please try again.')
            break
          default:
            setStatus('error')
            setMessage('Something went wrong.')
            break
        }
      })
      .catch((error) => {
        console.error('Error retrieving payment intent:', error)
        setStatus('error')
        setMessage('An unexpected error occurred.')
      })
  }, [stripe, navigate, clearCart]) // Add clearCart to dependencies

  return (
    <div className="max-w-2xl mx-auto">
      <PaymentStatus status={status} message={message} />
    </div>
  )
}

function PaymentConfirmation() {
  return (
    <CartProvider>
      <StripeProvider>
        <BasePage title="Payment Status">
          <PaymentConfirmationContent />
        </BasePage>
      </StripeProvider>
    </CartProvider>
  )
}

PaymentStatus.propTypes = {
  status: PropTypes.oneOf(['processing', 'success', 'error']).isRequired,
  message: PropTypes.string
}

PaymentStatus.defaultProps = {
  message: ''
}

export default PaymentConfirmation